import React, { ReactNode, useEffect, useRef } from 'react';
import BackArrow from '../icons/BackArrowIcon';

interface RightSideModalProps {
  isOpen: boolean;
  onClose: (d: any, t?: any, z?: any) => void;
  children: ReactNode;
  modal?: string;
  data?: any;
  isOtherModalOpen?: any;
  setRowClass?: any;
}

const RightSideModal: React.FC<RightSideModalProps> = ({
  isOpen,
  onClose,
  children,
  modal,
  data,
  isOtherModalOpen,
  setRowClass,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        if (!isOtherModalOpen) {
          onClose(data, false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose, isOtherModalOpen]);

  return (
    <div className={`right-side-modal ${isOpen ? 'open' : ''}`}>
      <div className="rightside-modal-content" ref={modalRef}>
        <span
          className="close-btn"
          role="button"
          onClick={() => onClose(data, true)}
        >
          <BackArrow /> Go Back
        </span>
        {children}
      </div>
    </div>
  );
};

export default RightSideModal;

import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import CalendarIcon from '../icons/CalendarIcon';
import EditIcon from '../icons/EditIcon';
import LeftArrowIcon from '../icons/LeftArrowIcon';
import RightArrowIcon from '../icons/RightArrowIcon';

interface ICalendarFilter {
  selectedDate: [Date | null, Date | null | string] | [] | string;
  setSelectedDate: (e: [Date | null, Date | null] | [] | string) => void;
  isClearFilter: boolean;
}

const EditIconMemoized = React.memo(EditIcon);

const CalendarFilter: React.FC<ICalendarFilter> = ({
  selectedDate,
  setSelectedDate,
  isClearFilter = false,
}) => {
  const [date, setDate] = useState<[Date | null, Date | null] | []>([]);
  const { register, setValue, getValues, watch } = useForm({
    mode: 'onChange',
  });

  const formatDate = (dateString: Date | undefined | null | string) => {
    if (!dateString) {
      return '';
    }
    const parsedDate = moment(dateString, 'MMM D, YYYY');
    if (parsedDate.isValid()) {
      return parsedDate.format('MMM D, YYYY');
    }
    return '';
  };

  const [isEdit, setIsEdit] = useState(false);
  const [isEditFromNumber, setIsEditFromNumber] = useState(true);
  const dateFormat =
    /^(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) [1-3]?[0-9], [0-9]{4}$/;

  const handleDateSubmit = () => {
    const startDate = getValues('startDate');
    const endDate = getValues('endDate');
    if (isEditFromNumber) {
      setDate([new Date(startDate), new Date(endDate)]);
    } else {
      setDate([]);
    }
    if (startDate && endDate) {
      const sDate = moment(startDate, 'MMM D, YYYY').format('YYYY-MM-DD');
      const eDate = moment(endDate, 'MMM D, YYYY').format('YYYY-MM-DD');
      setSelectedDate(`${sDate} <> ${eDate}`);
    }
  };

  const handleEditToggle = () => {
    setIsEdit(!isEdit);
    setIsEditFromNumber(false);
  };

  const handleBlur = () => {
    setValue('startDate', formatDate(getValues('startDate')) || '', {
      shouldValidate: true,
    });
    setValue('endDate', formatDate(getValues('endDate')) || '', {
      shouldValidate: true,
    });
    setIsEdit(false);
  };

  const validateDate = (inputValue: string) => {
    return dateFormat.test(inputValue);
  };

  const handleReset = () => {
    setSelectedDate([]);
    setDate([]);
    setValue('startDate', null);
    setValue('endDate', null);
  };

  useEffect(() => {
    if (date[0]) {
      setValue('startDate', formatDate(date[0]) || '', {
        shouldValidate: true,
      });
    }
    if (date[1]) {
      setValue('endDate', formatDate(date[1]) || '', {
        shouldValidate: true,
      });
    }
  }, [date]);

  useEffect(() => {
    if (isClearFilter) {
      handleReset();
    }
  }, [isClearFilter]);

  const renderCustomHeader = (
    headerProps: ReactDatePickerCustomHeaderProps,
  ) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '15px',
          background: 'white',
        }}
      >
        <h4 style={{ alignSelf: 'flex-start' }}>Select date range</h4>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <input
            type="text"
            {...register('startDate', {
              pattern:
                /^(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) [0-3]?[0-9], [0-9]{4}$/,
              validate: validateDate,
            })}
            placeholder="Start date"
            readOnly={!isEdit}
            contentEditable={isEdit}
            style={{
              border: 'none',
              padding: '0',
              margin: '0',
              fontSize: '18px',
              fontWeight: 'bold',
              color: 'inherit',
              background: 'none',
              outline: 'none',
              width: '100%',
            }}
            onBlur={handleBlur}
          />
          <input
            type="text"
            {...register('endDate', {
              pattern:
                /^(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) [0-3]?[0-9], [0-9]{4}$/,
              validate: validateDate,
            })}
            placeholder="End date"
            readOnly={!isEdit}
            contentEditable={isEdit}
            style={{
              border: 'none',
              padding: '0',
              margin: '0',
              fontSize: '18px',
              fontWeight: 'bold',
              color: 'inherit',
              background: 'none',
              outline: 'none',
              width: '100%',
            }}
            onBlur={handleBlur}
          />
          <span role="button" onClick={handleEditToggle}>
            <EditIconMemoized />
          </span>
        </div>
        <hr />
        <div
          style={{
            display: 'flex',
            marginTop: '15px',
            justifyContent: 'space-between',
          }}
        >
          <span>{moment(headerProps.date).format('MMMM, YYYY')}</span>
          <span>
            <span role="button" onClick={() => headerProps.decreaseMonth()}>
              <LeftArrowIcon />
            </span>
            <span role="button" onClick={() => headerProps.increaseMonth()}>
              <RightArrowIcon />
            </span>
          </span>
        </div>
      </div>
    );
  };

  return (
    <Form className="ml-auto mr-3">
      <div style={{ position: 'relative', marginBottom: '22px' }}>
        <div className="custom-calendar">
          <DatePicker
            value={
              (watch('startDate')
                ? `${moment(watch('startDate')).format('MMM D, YYYY')} -`
                : 'Start date - ') +
              (watch('endDate')
                ? ` ${moment(watch('endDate')).format('MMM D, YYYY')}`
                : ' End date')
            }
            onChange={(d) => {
              setIsEditFromNumber(true);
              setDate(d);
            }}
            renderCustomHeader={renderCustomHeader}
            startDate={date[0]}
            endDate={date[1]}
            selectsRange
            shouldCloseOnSelect={false}
            formatWeekDay={(day) => {
              return day.charAt(0);
            }}
            // eslint-disable-next-line react/no-unstable-nested-components
            calendarContainer={(props) => {
              return (
                <div className="react-datepicker">
                  {props.children}
                  <div
                    style={{
                      display: 'flex',
                      width: '50%',
                      float: 'right',
                      padding: '0px 20px 10px 10px',
                      justifyContent: 'space-between',
                      marginTop: '8%',
                    }}
                  >
                    <span
                      onClick={handleReset}
                      role="button"
                      className="reset-button"
                    >
                      Reset
                    </span>
                    <span
                      onClick={handleDateSubmit}
                      role="button"
                      className="apply-button"
                    >
                      Apply
                    </span>
                  </div>
                </div>
              );
            }}
          />
          <span className="icon-container">
            <CalendarIcon />
          </span>
        </div>
      </div>
    </Form>
  );
};
export default React.memo(CalendarFilter);

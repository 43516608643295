const pagesSlug = {
  dashboard: 'dashboard',
  newOrders: 'newOrders',
  kitting: 'productKitting',
  delivered: 'orderInDelivery',
  shipping: 'orderInShipping',
  users: 'users',
  settings: 'settings',
  logout: 'logout',
};

export default pagesSlug;

import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from 'react-query';
import setToken from '../../utils/api';
import { IApiResponse } from '../../types/IApiResponse';

export default function useDeleteUser(id: string | undefined) {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  return useMutation<any, any, { userId: string }>(
    ['delete-user', id],
    async ({ userId }: any) => {
      if (!id && !userId) {
        return null;
      }
      const token = await getAccessTokenSilently();
      const api = await setToken(token);
      const response = await api.delete<IApiResponse<any>>(
        `api/users/${id || userId}`,
      );

      if (response.status !== 200) {
        throw response.data;
      }
      return 'ok';
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['user-details', id]);
        queryClient.invalidateQueries('user-list');
      },
    },
  );
}

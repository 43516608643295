import { useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useQueryParams = () => {
  const { search: query } = useLocation();
  const params: { [key: string]: string | string[] } = {};
  const memoizedQuery = useMemo(() => query, [query]);
  const searchParams = useMemo(
    () => new URLSearchParams(memoizedQuery),
    [memoizedQuery],
  );

  useEffect(() => {
    if (searchParams.entries().next().value) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of searchParams.entries()) {
        if (!params[key]) {
          params[key] = value;
        } else {
          if (!Array.isArray(params[key])) {
            params[key] = [params[key] as string];
          }
          (params[key] as string[]).push(value);
        }
      }
    }
  }, [searchParams]); // Empty dependency array ensures the effect runs only once

  return { params, searchParams, query };
};

export default useQueryParams;

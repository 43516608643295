import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Spinner } from 'react-bootstrap';
import { UserItem } from '../../types/IUserResponse';

interface UserDetailsModalProps {
  onSubmit: (data: UserItem) => void;
  data: UserItem;
  isLoading: boolean;
}

const UserDetailsModal: React.FC<UserDetailsModalProps> = ({
  onSubmit,
  data,
  isLoading,
}) => {
  const { register, handleSubmit, reset } = useForm<UserItem>({
    defaultValues: data,
  });

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  const handleFormSubmit = (formData: UserItem) => {
    onSubmit(formData);
  };

  return (
    <form className="input-fields" onSubmit={handleSubmit(handleFormSubmit)}>
      <h4>
        User Details
        <br />
        <span style={{ fontWeight: 400, fontSize: 14 }}>
          Enter user details and hit save button
        </span>
      </h4>

      <div className="input-group">
        <label htmlFor="firstName">First Name:</label>
        <input type="text" id="firstName" {...register('firstName')} />
      </div>

      <div className="input-group">
        <label htmlFor="lastName">Last Name:</label>
        <input type="text" id="lastName" {...register('lastName')} />
      </div>

      <div className="input-group">
        <label htmlFor="role">Role:</label>
        <select id="role" className="select-field" {...register('role')}>
          <option value="Admin">Admin</option>
          <option value="Member">Member</option>
        </select>
      </div>

      <button type="submit" className="save-button">
        {isLoading ? <Spinner size="sm" /> : 'Submit'}
      </button>
    </form>
  );
};

export default UserDetailsModal;

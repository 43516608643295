import React from 'react';

const SignoutIcon = () => {
  return (
    <svg width="20" height="20" viewBox="2 0 23 22" fill="none">
      <path
        d="M13.5946 7.21875L16.875 10.5L13.5946 13.7812M8.125 10.5H16.8727M8.125 17.375H3.75C3.58424 17.375 3.42527 17.3092 3.30806 17.1919C3.19085 17.0747 3.125 16.9158 3.125 16.75V4.25C3.125 4.08424 3.19085 3.92527 3.30806 3.80806C3.42527 3.69085 3.58424 3.625 3.75 3.625H8.125"
        stroke="#475569"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SignoutIcon;

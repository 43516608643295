import React from 'react';

const EditIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 3 48 48" fill="none">
      <g clipPath="url(#clip0_331_4355)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.06 15.5899L32.41 16.9399C33.2 17.7199 33.2 18.9899 32.41 19.7699L19.18 32.9999H15V28.8199L25.4 18.4099L28.23 15.5899C29.01 14.8099 30.28 14.8099 31.06 15.5899ZM17 30.9999L18.41 31.0599L28.23 21.2299L26.82 19.8199L17 29.6399V30.9999Z"
          fill="#1E293B"
        />
      </g>
      <defs>
        <clipPath id="clip0_331_4355">
          <rect x="4" y="4" width="40" height="40" rx="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EditIcon;

import { useInfiniteQuery } from 'react-query';

import { useAuth0 } from '@auth0/auth0-react';
import setToken from '../../utils/api';
import UserData from '../../types/IUserResponse';

export default function useUserList({ limit = 10, page = 1, filter = {} }) {
  const { getAccessTokenSilently } = useAuth0();
  return useInfiniteQuery<UserData>(
    ['user-list', limit, page, filter],
    async ({ pageParam = 1 }) => {
      const token = await getAccessTokenSilently();
      const api = await setToken(token);
      const response = await api.get<UserData>('api/users', {
        params: { page: page || pageParam, pageSize: limit, ...filter },
      });

      if (response.status === 200) {
        return response?.data;
      }

      throw new Error('Users not found');
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      // eslint-disable-next-line consistent-return
      getNextPageParam: (lastPage) => {
        if (lastPage?.currentPage < lastPage.maxPage) {
          return lastPage.currentPage + 1;
        }
      },
    },
  );
}

import { useMutation, useQueryClient } from 'react-query';

import { useAuth0 } from '@auth0/auth0-react';
import setToken from '../../utils/api';

import { IApiResponse } from '../../types/IApiResponse';

export default function useCreateUser() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  return useMutation<IApiResponse<any>, IApiResponse<any>, any>(
    ['create-user'],
    async ({ ...data }: any) => {
      const token = await getAccessTokenSilently();
      const api = await setToken(token);
      const response = await api.post<IApiResponse<any>>('api/users', data);

      if (response.status !== 201 && response.status !== 200) {
        throw response.data;
      }

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('user-list');
      },
    },
  );
}

import React from 'react';

const BackArrowIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M9.53125 16.125L3.90625 10.5L9.53125 4.875"
        stroke="#475569"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.6875 10.5H16.0938"
        stroke="#475569"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BackArrowIcon;

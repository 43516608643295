import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from 'react-query';
import setToken from '../../utils/api';
import { IApiResponse } from '../../types/IApiResponse';
import { IOrderItem } from '../../types/IOrderResponse';

export default function useChangePasswordByAdmin() {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation<any, any, Partial<IOrderItem>>(
    ['change-user-password'],
    async (data: any) => {
      if (!data.id) {
        throw new Error('user id not found');
      }
      const token = await getAccessTokenSilently();
      const api = await setToken(token);
      const response = await api.put<IApiResponse<any>>(
        `api/users/${data?.id}/admin-change-password`,
        {
          password: data?.password,
          confirmPassword: data?.confirmPassword,
        },
      );
      if (response.status !== 200) {
        throw response.data;
      }
      return 'ok';
    },
  );
}

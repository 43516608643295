import React from 'react';

const ShippingIcon = () => {
  return (
    <svg width="20" height="20" viewBox="2 0 23 22" fill="none">
      <g clipPath="url(#clip0_100_929)">
        <path
          d="M13.3334 9.41675L9.16675 6.91675L13.3334 4.41675L17.5001 6.91675V11.5001L13.3334 14.0001V9.41675Z"
          stroke="#475569"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.16675 6.91675V11.5001L13.3334 14.0001"
          stroke="#475569"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3333 9.4542L17.4999 6.9292"
          stroke="#475569"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.83341 6.5H1.66675"
          stroke="#475569"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.83325 9H3.33325"
          stroke="#475569"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.83333 11.5H5"
          stroke="#475569"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_100_929">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShippingIcon;

import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from 'react-query';
import setToken from '../../utils/api';
import { IApiResponse } from '../../types/IApiResponse';

export default function useDeleteOrder(id: number | undefined) {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  return useMutation<any, any, { orderId: number }>(
    ['delete-order', id],
    async ({ orderId }: any) => {
      if (!id && !orderId) {
        return null;
      }
      const token = await getAccessTokenSilently();
      const api = await setToken(token);
      const response = await api.delete<IApiResponse<any>>(
        `api/orders/${id || orderId}`,
      );

      if (response.status !== 200) {
        throw response.data;
      }
      return 'ok';
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['order-details', id]);
        queryClient.invalidateQueries('order-list');
        queryClient.invalidateQueries('order-count');
      },
    },
  );
}

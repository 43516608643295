import React, { useEffect, useState } from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Link,
  Font,
} from '@react-pdf/renderer';
import moment from 'moment';
import logoBase64 from '../../constant/logo-base64';
// @ts-ignore
import Roboto500 from '../../fonts/Roboto-Medium.ttf';
// @ts-ignore
import Roboto300 from '../../fonts/Roboto-Light.ttf';

Font.register({
  family: 'Roboto',
  fonts: [{ src: Roboto500, fontWeight: 'normal' }],
});

Font.register({
  family: 'Roboto',
  fonts: [{ src: Roboto300, fontWeight: 'light' }],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  container: {
    flex: 1,
    alignItems: 'center',
    fontFamily: 'Roboto',
    marginTop: '5%',
  },
  box: {
    width: '78%',
    height: '20%',
    border: 2,
    borderColor: 'black',
    display: 'flex',
    flexDirection: 'row',
  },
  logo: {
    width: '40%',
  },
  content: {
    flex: 2,
    padding: 15,
    fontWeight: 'bold',
    left: '32%',
    zIndex: 10,
  },
  title: {
    fontSize: 14,
    marginBottom: 10,
    marginTop: 15,
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  listItem: {
    fontSize: 14,
    marginBottom: 5,
    fontWeight: 'light',
  },
  link: {
    fontSize: 14,
    marginTop: 55,
    fontWeight: 'light',
    textDecoration: 'none',
    color: 'black',
  },
  signedByText: {
    fontSize: 14,
    fontWeight: 'light',
  },
  signedByBoldText: {
    fontSize: 14,
    fontWeight: 'normal',
  },
  centeredBoldText: {
    fontSize: 16,
    fontWeight: 'normal',
    alignSelf: 'center',
  },
  centeredText: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 30,
  },
  logoBackground: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.2,
    zIndex: -1,
  },
});

interface Iprops {
  kitter: string;
  customerName: string;
  quantity: number;
}

const MyDocument = ({ kitter, customerName, quantity }: Iprops) => {
  return (
    <Document>
      <Page size="A3" style={styles.page}>
        <View style={styles.container}>
          <View style={styles.box}>
            <View style={styles.logoBackground}>
              <Image src={logoBase64} style={styles.logo} />
            </View>
            <View style={styles.centeredText}>
              <Text style={styles.centeredBoldText}>Flash Title Change</Text>
            </View>
            <View style={styles.content}>
              <Text style={styles.title}>System Generated Certificate</Text>
              <Text style={styles.listItem}>
                <Text style={styles.signedByText}>Digitally signed by </Text>
                <Link
                  src="https://distribution.innovarxi.com/"
                  style={[styles.link, styles.signedByBoldText]}
                >
                  distribution.innovarxi.com
                </Link>
              </Text>
              <Text style={styles.listItem}>
                Digital/Title change to {kitter}
              </Text>
              <Text style={styles.listItem}>
                Customer Name={customerName}, Quantity Ordered={quantity}
              </Text>
              <Text style={styles.listItem}>
                Date: {moment().format('YYYY-MM-DD:HH:mm:ssZ')}{' '}
              </Text>
              <Link
                src="https://distribution.innovarxi.com/"
                style={styles.link}
              >
                distribution.innovarxi.com
              </Link>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;

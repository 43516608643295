import React from 'react';

interface NotificationIconProps {
  count: number;
  color: string | undefined;
}

const NotificationIcon: React.FC<NotificationIconProps> = ({
  count,
  color = '#475569',
}) => {
  return (
    <div
      style={{
        position: 'relative',
        display: count > 0 ? 'inline-block' : 'none',
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        backgroundColor: count > 0 ? color : 'transparent',
        color: 'white',
        fontWeight: 'normal',
        textAlign: 'center',
        lineHeight: '20px',
        float: 'right',
        fontSize: '12px',
      }}
    >
      {count}
    </div>
  );
};

export default NotificationIcon;

import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from 'react-query';
import setToken from '../../utils/api';
import { IApiResponse } from '../../types/IApiResponse';
import { IOrderItem } from '../../types/IOrderResponse';

export default function useOrderUpdate(id: number | undefined) {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  return useMutation<any, any, Partial<IOrderItem>>(
    ['order-update'],
    async (data: any) => {
      if (!id && !data.id) {
        return null;
      }
      const token = await getAccessTokenSilently();
      const api = await setToken(token);
      const response = await api.put<IApiResponse<any>>(
        `api/orders/${id || data.id}`,
        {
          ...data,
        },
      );

      if (response.status !== 200) {
        throw response.data;
      }
      return 'ok';
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['order-count']);
        queryClient.invalidateQueries('order-details');
        queryClient.invalidateQueries(['order-details', id]);
        queryClient.invalidateQueries('order-list');
      },
    },
  );
}

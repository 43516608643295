import React from 'react';

const PackageIcon = () => {
  return (
    <svg width="20" height="20" viewBox="2 0 23 22" fill="none">
      <path
        d="M10 18.8456C9.89266 18.8456 9.78714 18.818 9.69359 18.7653L2.81859 14.8981C2.72193 14.8438 2.64148 14.7647 2.58549 14.6689C2.52951 14.5732 2.5 14.4643 2.5 14.3534V6.64697C2.5 6.53607 2.52951 6.42717 2.58549 6.33144C2.64148 6.23571 2.72193 6.15661 2.81859 6.10224L9.69359 2.23505C9.78714 2.18243 9.89266 2.15479 10 2.15479C10.1073 2.15479 10.2129 2.18243 10.3064 2.23505L17.1814 6.10224C17.2781 6.15661 17.3585 6.23571 17.4145 6.33144C17.4705 6.42717 17.5 6.53607 17.5 6.64697V14.3534C17.5 14.4643 17.4705 14.5732 17.4145 14.6689C17.3585 14.7647 17.2781 14.8438 17.1814 14.8981L10.3064 18.7653C10.2129 18.818 10.1073 18.8456 10 18.8456ZM10 18.8456L10.0741 10.5002M13.8298 12.4151V8.35261L6.25 4.17207M17.4138 6.33043L10.0741 10.5002M10.0741 10.5002L2.58656 6.32964"
        stroke="#475569"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PackageIcon;

import React from 'react';

const CancelIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M5.63616 5.63604L18.3641 18.364M21.0001 12C21.0001 16.9706 16.9707 21 12.0001 21C7.02956 21 3.00012 16.9706 3.00012 12C3.00012 7.02944 7.02956 3 12.0001 3C16.9707 3 21.0001 7.02944 21.0001 12Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CancelIcon;

import React from 'react';

const ListIcon = () => {
  return (
    <svg width="20" height="20" viewBox="2 0 23 22" fill="none">
      <g clipPath="url(#clip0_100_943)">
        <path
          d="M8.01258 16.6667H5.83341C5.39139 16.6667 4.96746 16.4911 4.6549 16.1785C4.34234 15.866 4.16675 15.442 4.16675 15V5C4.16675 4.55798 4.34234 4.13405 4.6549 3.82149C4.96746 3.50893 5.39139 3.33334 5.83341 3.33334H12.5001C12.9421 3.33334 13.366 3.50893 13.6786 3.82149C13.9912 4.13405 14.1667 4.55798 14.1667 5V11.6667"
          stroke="#475569"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6667 15.8333L13.3334 17.5L16.6667 14.1667"
          stroke="#475569"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 6.66666H10.8333"
          stroke="#475569"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 10H9.16667"
          stroke="#475569"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_100_943">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ListIcon;

import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Auth from '../pages/Auth';

const Logout: React.FC = () => {
  const { logout, isAuthenticated } = useAuth0();

  return isAuthenticated ? <Auth /> : null;
};

export default Logout;

import React from 'react';

const DownloadIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M20.25 14.75V20C20.25 20.1989 20.171 20.3897 20.0303 20.5303C19.8897 20.671 19.6989 20.75 19.5 20.75H4.5C4.30109 20.75 4.11032 20.671 3.96967 20.5303C3.82902 20.3897 3.75 20.1989 3.75 20V14.75M8.0625 10.8135L12 14.75L15.9375 10.8135M12 4.25V14.7472"
        stroke="#475569"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownloadIcon;

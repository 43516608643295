import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CloseButton, Form, FormControl } from 'react-bootstrap';
import FilterIcon from '../icons/FilterIcon';
import CarretDown from '../icons/CarretDown';
import truncateText from '../../utils/truncateText';

type ICalendarFilter = {
  searchItems: string[] | null;
  handleApply: (selectedItem: any, isClear?: any) => void;
  isLast?: boolean;
  selectedItems: string[];
  setSelectedItems: any;
  search: string;
  setSearch: (e: string) => void;
  dropdownRef: any;
  dropdownOpen: boolean;
  setDropdownOpen: (e: boolean) => void;
  isUserFilter?: boolean;
};

const DropDownFilter: React.FC<ICalendarFilter> = ({
  searchItems,
  handleApply,
  isLast = false,
  selectedItems,
  setSelectedItems,
  search,
  setSearch,
  dropdownRef,
  dropdownOpen,
  setDropdownOpen,
  isUserFilter,
}) => {
  // const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const checkboxRefs = useRef([]);
  // const handleCheckboxChange = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  //   value: string,
  // ) => {
  //   const isChecked = event.target.checked;

  //   if (isChecked) {
  //     setSelectedItems((prevSelected: string[]) => [value]);
  //   } else {
  //     setSelectedItems([]);
  //   }
  // };

  const handleCheckboxChange = (value: string) => {
    const isSelected = selectedItems.includes(value);

    if (isSelected) {
      setSelectedItems((prevSelected: any[]) =>
        prevSelected.filter((item: string) => item !== value),
      );
    } else {
      setSelectedItems((prevSelected: any) => [...prevSelected, value]);
    }
  };

  const handleClearSearch = () => {
    setSelectedItems([]);
    setSearch('');
  };

  const itemsList = useMemo(() => {
    const noOfKits = searchItems;
    if (search && noOfKits?.length) {
      return noOfKits.filter((value) => search?.includes(value.toString()));
    }
    return noOfKits;
  }, [search, searchItems]);

  const handleTitleClick = (index: number, value: string) => {
    const checkbox = checkboxRefs.current[index];
    if (checkbox) {
      // @ts-ignore
      const isChecked = checkbox.checked;
      if (!isChecked) {
        setSelectedItems([value]);
      } else {
        setSelectedItems([]);
      }
    }
  };

  return (
    <Form className="ml-auto" ref={dropdownRef}>
      <div
        style={{ position: 'relative', marginBottom: isLast ? '-5px' : '22px' }}
      >
        <span
          onClick={toggleDropdown}
          style={{
            position: 'absolute',
            top: '34%',
            left: '10px',
            transform: 'translateY(-50%)',
            width: '16px',
            height: '16px',
          }}
          role="button"
        >
          <FilterIcon />
        </span>
        <span
          style={{
            position: 'absolute',
            top: '38%',
            left: isUserFilter ? '75%' : '85%',
            transform: 'translateY(-50%)',
            height: '16px',
            fontSize: '10px',
            textDecoration: 'underline',
            color: 'blue',
          }}
          role="button"
          onClick={toggleDropdown}
        >
          <CarretDown />
        </span>
        <FormControl
          type="text"
          placeholder={`Filter by: ${
            // eslint-disable-next-line no-nested-ternary
            selectedItems?.length === 1
              ? truncateText(selectedItems[0], 13)
              : selectedItems?.length > 1
              ? `${selectedItems?.length} Items`
              : 'none'
          }`}
          style={{
            width: isUserFilter ? '222.6px' : '170.6px',
            height: '34px',
            fontSize: '12px',
            borderRadius: '48px',
            padding: '1px 25px',
            border: '1px solid #475569',
            backgroundColor: 'white',
            paddingLeft: '29px',
          }}
          onChange={(e) => setSearch(e.target.value)}
          onClick={toggleDropdown}
          value={search}
        />
        {dropdownOpen && (
          <div
            style={{
              position: 'absolute',
              top: '100%',
              right: -5,
              backgroundColor: 'white',
              border: '0px solid #475569',
              width: '221px',
              height: '250px',
              padding: '16px 20px 16px 20px',
              borderRadius: '20px',
              boxShadow: '0px 10px 14px 1px #00000008',
              overflowY: 'hidden',
              zIndex: 100,
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              scrollbarWidth: 'thin',
              scrollbarColor: '#475569 #E2E8F0',
            }}
          >
            <div
              className="scroll-bar-filter"
              style={{
                height: '250px',
                overflowY: 'auto',
                zIndex: 100,
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              {!itemsList?.length
                ? 'No item found'
                : itemsList?.map((v, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px',
                        fontSize: '14px',
                        lineHeight: '21px',
                      }}
                      // onClick={() => handleTitleClick(index, v.toString())}
                      onClick={() => handleCheckboxChange(v.toString())}
                      role="button"
                    >
                      <input
                        type="checkbox"
                        // @ts-ignore
                        // eslint-disable-next-line no-return-assign
                        ref={(el) => (checkboxRefs.current[index] = el)}
                        key={`${v}-${index}`}
                        // onChange={(e) => handleCheckboxChange(e, v.toString())}
                        // checked={selectedItems[0] === v.toString()}
                        checked={selectedItems.includes(v.toString())}
                        style={{ marginRight: '5px' }}
                        multiple
                      />
                      {v}
                    </div>
                  ))}
            </div>
            <div
              style={{
                marginTop: 'auto',
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '6px',
              }}
            >
              <span
                style={{
                  height: '16px',
                  fontSize: '10px',
                  color: 'blue',
                  margin: '10px',
                }}
                role="button"
                onClick={() => {
                  handleApply([], true);
                  handleClearSearch();
                  setDropdownOpen(false);
                }}
              >
                <CloseButton />
              </span>
              <span
                onClick={() => {
                  handleClearSearch();
                }}
                role="button"
                className="reset-button"
              >
                Reset
              </span>
              <span
                onClick={() => {
                  handleApply(selectedItems);
                  setDropdownOpen(false);
                }}
                role="button"
                className="apply-button"
              >
                Apply
              </span>
            </div>
          </div>
        )}
      </div>
    </Form>
  );
};
DropDownFilter.defaultProps = {
  isLast: false,
};

export default DropDownFilter;

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Spinner } from 'react-bootstrap';
import { UserItem } from '../../types/IUserResponse';

interface FormData {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

interface UserDetailsModalProps {
  onSubmit: (data: FormData) => void;
  data: UserItem;
  isModalOpen: boolean;
  isLoading: boolean;
}

const EditPassword: React.FC<UserDetailsModalProps> = ({
  onSubmit,
  data,
  isModalOpen,
  isLoading = false,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onChange',
  });

  const handleFormSubmit = (formData: FormData) => {
    onSubmit(formData);
  };

  useEffect(() => {
    reset();
  }, [isModalOpen]);

  return (
    <div className="input-fields">
      <h4>
        User Password
        <br />
        <span style={{ fontWeight: 400, fontSize: 14 }}>
          Enter password and hit save button
        </span>
      </h4>

      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="input-group">
          <label htmlFor="oldPassword">Old Password:</label>
          <input
            type="password"
            id="oldPassword"
            {...register('oldPassword', { required: true })}
          />
          {errors.oldPassword && (
            <span className="error-text">This field is required</span>
          )}
        </div>

        <div className="input-group">
          <label htmlFor="password">New Password:</label>
          <input
            type="password"
            id="password"
            {...register('password', { required: true })}
          />
          {errors.password && (
            <span className="error-text">This field is required</span>
          )}
        </div>

        <div className="input-group">
          <label htmlFor="confirmPassword">Confirm Password:</label>
          <input
            type="password"
            id="confirmPassword"
            {...register('confirmPassword', {
              required: true,
              validate: (value) =>
                value === watch('password') || 'Passwords do not match',
            })}
          />
          {errors.confirmPassword && (
            <span className="error-text">{errors.confirmPassword.message}</span>
          )}
        </div>

        <button className="save-button" type="submit">
          {isLoading ? <Spinner size="sm" /> : 'Change Password'}
        </button>
      </form>
    </div>
  );
};

export default EditPassword;

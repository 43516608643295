import React, { useEffect, useState, useCallback } from 'react';
import { Table, Pagination, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import { PDFViewer, pdf } from '@react-pdf/renderer';
import { debounce } from 'lodash';
import PencilIcon from '../icons/PencilIcon';
import TrashIcon from '../icons/TrashIcon';
import DownloadIcon from '../icons/DownloadIcon';
import BackArrow from '../icons/BackArrowIcon';
import DropdownButton from '../dropdownButton/DropdownButton';
import useQueryParams from '../../hooks/useQueryParams';
import { OrdersData } from '../../types/IOrderResponse';
import DeleteConfirmationModal from '../modals/DeleteConfirmationModal';
import useDeleteOrder from '../../hooks/reactQuery/useDeleteOrder';
import getRandomColor from '../../utils/getRandomColor';
import TableFilters from '../OrderTableFilters';
import PdfDocument from '../pdf/RenderFlashUrlPdf';
import convertUtcToLocal from '../../utils/getLocalDateTime';
import getRandomClass from '../../utils/getRandomStatusClass';

interface IDashboardOrderTableProps {
  data: OrdersData | undefined;
  email: string | undefined;
  setEmail: (email: string) => void;
  phone: string | undefined;
  setPhone: (phone: string) => void;
  itemsPerPage: number;
  handleModalOpen: (data: any) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  isFetching: boolean;
  setCustomerName: (s: any) => void;
  searchCustomerName: string | undefined;
  pid: string | undefined;
  setPid: (pid: string) => void;
  setKitters: (e: any) => void;
  setStatuses: (e: any) => void;
  selectedStatuses: string[] | undefined;
  selectedKitters: string[] | undefined;
  selectedDate: Date | Date[] | null | any;
  setSelectedDate: (e: any) => void;
  rowDetails: any;
  setRowDetails: any;
}

const DashboardOrdersTable: React.FC<IDashboardOrderTableProps> = ({
  data,
  handleModalOpen,
  itemsPerPage,
  currentPage,
  setCurrentPage,
  isFetching,
  setCustomerName,
  searchCustomerName,
  pid,
  setPid,
  setKitters,
  selectedKitters,
  setStatuses,
  selectedStatuses,
  selectedDate,
  setSelectedDate,
  rowDetails,
  setRowDetails,
  email,
  setEmail,
  phone,
  setPhone,
}) => {
  const { params, searchParams, query } = useQueryParams();
  const history = useHistory();
  const [initialMount, setInitialMount] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemId, setItemId] = useState(0);
  const { mutate: deleteOrder } = useDeleteOrder(itemId);
  const [selected, setSelected] = useState<any>([]);
  const [selectedStatusItems, setSelectedStatusItems] = useState<string[]>([]);
  const [selectedKitterItems, setSelectedKitterItems] = useState<string[]>([]);

  const hadnleClearFilter = () => {
    setPid('');
    searchParams.delete('pid');
    setCustomerName('');
    searchParams.delete('customerName');
    setSelectedDate(null);
    setStatuses([]);
    setKitters([]);
    setEmail('');
    setPhone('');
    searchParams.delete('status');
    searchParams.delete('quantity');
    searchParams.delete('kitter');
    searchParams.delete('email');
    searchParams.delete('phone');
  };

  const handleSelect = (id: any) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item: any) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const totalPages = data?.maxPage;

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleDelete = (orderId: number) => {
    deleteOrder(
      { orderId },
      {
        onSuccess: () => {
          toast.success(`Order Deleted`);
          setIsModalOpen(false);
        },
        onError: () => {
          toast.error(`Failed to delete`);
        },
      },
    );
  };

  const handleKitterApply = (selectedItems: any, isClear = false) => {
    setKitters(selectedItems);
    if (isClear) {
      searchParams.delete('kitter');
    }
  };

  const handleStatusApply = (selectedStatusesItems: any, isClear = false) => {
    setStatuses(selectedStatusesItems);
    if (isClear) {
      searchParams.delete('status');
    }
  };

  const [pageSize, setPageSize] = useState<number>(itemsPerPage);

  useEffect(() => {
    if (currentPage) {
      searchParams.set('page', currentPage?.toString());
    }
    if (pageSize) {
      searchParams.set('pageSize', pageSize?.toString());
    }
    if (searchCustomerName) {
      searchParams.set('customerName', searchCustomerName);
    }
    if (pid) {
      searchParams.set('pid', pid);
    }
    if (email) {
      searchParams.set('email', email);
    }
    if (phone) {
      searchParams.set('phone', phone);
    }
    if (selectedKitters?.length) {
      const allKitters = selectedKitters.join('|');
      searchParams.set('kitter', allKitters);
    }
    if (selectedStatuses?.length) {
      searchParams.set('status', selectedStatuses.join('|'));
    }
    // if (selectedDate?.length) {
    //   searchParams.set(
    //     'orderDate',
    //     `${selectedDate[0]} <> ${
    //       selectedDate[1]
    //     }`,
    //   );
    // }
    history.push({
      search: searchParams?.toString(),
    });
  }, [currentPage, pid, searchCustomerName, selectedStatuses, selectedKitters, email, phone]);

  const updateStateFromURL = useCallback(() => {
    const storedCurrentPage = parseInt(params.page?.toString(), 10);
    const storedPageSize = parseInt(params.pageSize?.toString(), 10);
    const storedCustomerName = params.customerName?.toString();
    const storedPid = params.pid?.toString();
    const storedEmail = params.email?.toString();
    const storedPhone = params.phone?.toString();
    const storedKitters = params.kitter?.toString();
    const storedStatus = params.status?.toString();
    const storedOrderDate = params.orderDate?.toString();
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(storedCurrentPage) && storedCurrentPage !== currentPage) {
      setCurrentPage(storedCurrentPage);
    }
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(storedPageSize) && storedPageSize !== pageSize) {
      setPageSize(storedPageSize);
    }
    if (storedCustomerName && storedCustomerName !== searchCustomerName) {
      setCustomerName(storedCustomerName);
    }
    if (storedPid && storedPid !== pid) {
      setPid(storedPid);
    }
    if (storedKitters && storedKitters !== selectedKitters?.join('|')) {
      setKitters(storedKitters.split('|'));
      setSelectedKitterItems(storedKitters.split('|'));
    }
    if (storedStatus && storedStatus !== selectedStatuses?.[0]) {
      setStatuses(storedStatus.split('|'));
      setSelectedStatusItems(storedStatus.split('|'));
    }
    if (storedEmail && storedEmail !== email) {
      setEmail(storedEmail);
    }
    if (storedPhone && storedPhone !== phone) {
      setPhone(storedPhone);
    }
    // if (storedOrderDate){
    //   const dates = storedOrderDate.split('<>');
    //   setSelectedDate([da])
    // }
  }, [query]);

  useEffect(() => {
    updateStateFromURL();
    setTimeout(() => setInitialMount(false), 10);
  }, []);

  useEffect(() => {
    if (!initialMount) {
      updateStateFromURL();
    }
  }, [query]);

  useEffect(() => {
    setRowDetails({
      class: 'table-primary',
    });
  }, []);

  useEffect(() => {
    let lastBlurTime = 0;

    const handleWindowFocus = () => {
      const currentTime = Date.now();
      // Check if the window lost focus more than, for example, 5 seconds ago
      if (currentTime - lastBlurTime > 5000) {
        setRowDetails({
          class: 'table-primary',
        });
      }
    };

    const handleWindowBlur = () => {
      lastBlurTime = Date.now();
    };

    window.addEventListener('focus', handleWindowFocus);
    window.addEventListener('blur', handleWindowBlur);

    return () => {
      window.removeEventListener('focus', handleWindowFocus);
      window.removeEventListener('blur', handleWindowBlur);
    };
  }, []);

  return (
    <>
      <div
        className="table-custom-boundries"
        style={{
          backgroundColor: '#ffffff',
          borderRadius: '24px',
        }}
      >
        {/* {search ? (
          <h4
            style={{ borderBottom: 2, marginBottom: '20px', display: 'flex' }}
          >
            <span style={{ display: 'flex', flexDirection: 'column' }}>
              <span
                style={{ fontSize: 14, fontWeight: 500 }}
                role="button"
                onClick={() => {
                  setSearch('');
                  history.push('/');
                }}
              >
                <BackArrow /> Back to Order List
              </span>
              Search Results
            </span>
            <span
              style={{
                float: 'right',
                fontSize: 14,
                fontWeight: 500,
                marginLeft: 'auto',
                marginRight: 5,
              }}
            >
              Results Found:
              <span style={{ fontWeight: 550, fontSize: 14 }}>
                {data?.total}
              </span>
            </span>
          </h4>
        ) : (
          <h4 style={{ borderBottom: 2, marginBottom: '20px' }}>
            Order List
            <span
              style={{ float: 'right', display: 'flex', alignItems: 'center' }}
            >
             
            </span>
          </h4>
        )} */}

        <Table
          bordered={false}
          className="custom-table"
          responsive
          style={{ marginBottom: '20px' }}
        >
          <thead>
            <tr>
              <th>PID</th>
              <th>Customer Name</th>
              <th>Email</th>
              <th>Phone</th>
              {/* <th>Quantity</th> */}
              <th>Order Date</th>
              <th>Order Status</th>
              <th>Kitter</th>
            </tr>
            <TableFilters
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              searchCustomerName={searchCustomerName}
              setSearchCustomerName={setCustomerName}
              searchPid={pid}
              setSearchPid={setPid}
              statusItems={data?.selections?.Status || []}
              kitterItems={data?.selections?.Kitter || []}
              handleKitterApply={handleKitterApply}
              handleStatusApply={handleStatusApply}
              searchParams={searchParams}
              handleClearFilters={hadnleClearFilter}
              selectedKitterItems={selectedKitterItems}
              selectedStatusItems={selectedStatusItems}
              setSelectedKitterItems={setSelectedKitterItems}
              setSelectedStatusItems={setSelectedStatusItems}
              email={email}
              setEmail={setEmail}
              phone={phone}
              setPhone={setPhone}
            />
          </thead>
          <tbody
            style={{
              borderColor: 'transparent',
              display: !data?.items.length || isFetching ? 'table-caption' : '',
            }}
          >
            {!data?.items.length || isFetching ? (
              <span className="spinner-position-between-table">
                {isFetching ? <Spinner /> : 'There is no order'}
              </span>
            ) : (
              data?.items?.map((order) => (
                <tr
                  role="button"
                  className={
                    (rowDetails && rowDetails.orderId === order.id) ||
                    (rowDetails &&
                      rowDetails.isActive &&
                      rowDetails.orderId === order.id)
                      ? rowDetails.class
                      : ''
                  }
                  key={order.id}
                  onMouseEnter={() => {
                    if (
                      rowDetails &&
                      order.id !== rowDetails.orderId &&
                      !rowDetails.isActive
                    ) {
                      setRowDetails({
                        class: 'table-primary',
                        orderId: order.id,
                      });
                    }
                  }}
                  onMouseLeave={() => {
                    if (rowDetails && !rowDetails.isActive) {
                      setRowDetails({
                        class: 'table-default',
                        orderId: order.id,
                      });
                    }
                  }}
                  onClick={() => {
                    setRowDetails({
                      class: 'table-primary',
                      orderId: order.id,
                      isActive: true,
                      isRow: true,
                    });
                    handleModalOpen({
                      modal: 'order',
                      isModalOpen,
                      data: {
                        ...order,
                      },
                      isRow: true,
                    });
                  }}
                >
                  <td>{order.pid}</td>
                  <td>{order.customerName}</td>
                  <td>{order.emailAddress}</td>
                  <td>{order.phoneNumber}</td>
                  {/* <td>{order.quantity}</td> */}
                  <td>{convertUtcToLocal(order.orderDate)}</td>
                  <td
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    <span className={getRandomClass(order.status)}>
                      {order.status}
                    </span>
                  </td>
                  {/* <td>
                    <span
                      className="ml-3"
                      role="button"
                      onClick={() => handleDownload(order.flashTitleUrl)}
                    >
                      <DownloadIcon />
                    </span>
                  </td> */}
                  {/* <td style={{ minWidth: '120px' }}>
                    <span
                      role="button"
                      onClick={() =>
                        handleModalOpen({
                          modal: 'order',
                          data: {
                            ...order,
                          },
                        })
                      }
                    >
                      <PencilIcon />
                    </span>
                    <span
                      role="button"
                      className="ml-2"
                      onClick={() => {
                        setItemId(order.id);
                        setIsModalOpen(true);
                      }}
                    >
                      <TrashIcon />
                    </span>
                  </td> */}
                  <td>{order.kitter}</td>
                </tr>
              ))
            )}
          </tbody>
        </Table>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Pagination>
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              style={{ fontWeight: 'bolder' }}
            >
              {'<'}
            </Pagination.Prev>
            {currentPage >= 3 ? <Pagination.Ellipsis /> : null}
            {[...Array(totalPages)].map((_, index: number) => {
              if (
                index + 1 === currentPage ||
                (index >= currentPage - 3 && index <= currentPage + 1)
              ) {
                return (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                );
              }
              return null;
            })}
            {currentPage + 3 <= (totalPages || 0) ? (
              <Pagination.Ellipsis />
            ) : null}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              style={{ fontWeight: 'bolder' }}
            >
              {'>'}
            </Pagination.Next>
          </Pagination>
        </div>
      </div>
      <DeleteConfirmationModal
        onDelete={handleDelete}
        onHide={() => setIsModalOpen(false)}
        show={isModalOpen}
        id={itemId}
        label="cancel"
        buttonType="Cancel Order"
      />
    </>
  );
};

export default React.memo(DashboardOrdersTable);

import React from 'react';

const UserIcon = () => {
  return (
    <svg width="20" height="20" viewBox="2 0 23 22" fill="none">
      <path
        d="M1.98808 17.1241C1.84994 17.3632 1.93179 17.6691 2.1709 17.8072C2.41 17.9453 2.71582 17.8635 2.85396 17.6244L1.98808 17.1241ZM17.1461 17.6245C17.2843 17.8636 17.5901 17.9455 17.8292 17.8074C18.0683 17.6692 18.1502 17.3634 18.012 17.1243L17.1461 17.6245ZM2.85396 17.6244C3.57847 16.3703 4.62024 15.329 5.87461 14.6051L5.37474 13.739C3.96838 14.5506 2.80038 15.7181 1.98808 17.1241L2.85396 17.6244ZM5.87461 14.6051C7.12899 13.8811 8.55179 13.5 10.0001 13.5L10.0001 12.5C8.37631 12.5 6.78111 12.9273 5.37474 13.739L5.87461 14.6051ZM10.0001 13.5C11.4484 13.5 12.8712 13.8812 14.1255 14.6051L14.6254 13.7391C13.2191 12.9273 11.6239 12.5 10.0001 12.5L10.0001 13.5ZM14.1255 14.6051C15.3799 15.3291 16.4216 16.3705 17.1461 17.6245L18.012 17.1243C17.1998 15.7183 16.0318 14.5508 14.6254 13.7391L14.1255 14.6051ZM14.5 8C14.5 10.4853 12.4854 12.5 10.0001 12.5V13.5C13.0377 13.5 15.5 11.0376 15.5 8H14.5ZM10.0001 12.5C7.5148 12.5 5.5 10.4853 5.5 8H4.5C4.5 11.0376 6.96254 13.5 10.0001 13.5V12.5ZM5.5 8C5.5 5.51472 7.51472 3.5 10 3.5V2.5C6.96244 2.5 4.5 4.96243 4.5 8H5.5ZM10 3.5C12.4853 3.5 14.5 5.51472 14.5 8H15.5C15.5 4.96243 13.0376 2.5 10 2.5V3.5Z"
        fill="#475569"
      />
    </svg>
  );
};

export default UserIcon;

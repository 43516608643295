function truncateText(text: string, maxLength: number): string {
  if (text.length <= maxLength) {
    return text;
  }

  // Truncate the text and add an ellipsis
  const truncatedText = `${text.slice(0, maxLength - 3)}...`;
  return truncatedText;
}

export default truncateText;

import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { IOrderItem } from '../../types/IOrderResponse';

interface OrderDetailsModalProps {
  onSubmit: (data: Partial<IOrderItem>) => void;
  isLoading: boolean;
}

const CreateOrderModal: React.FC<OrderDetailsModalProps> = ({
  onSubmit,
  isLoading = false,
}) => {
  const [formData, setFormData] = useState<Partial<IOrderItem>>({
    pid: '',
    customerName: '',
    quantity: 0,
    orderDate: '',
    status: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = () => {
    onSubmit(formData);
  };

  return (
    <div className="input-fields">
      <h4>
        Create Order
        <br />
        <span style={{ fontWeight: 400, fontSize: 14 }}>
          Enter order details and hit save button
        </span>
      </h4>
      <div className="input-group">
        <label htmlFor="pid">PID:</label>
        <input
          autoComplete="off"
          type="text"
          id="pid"
          className="border-radius"
          name="pid"
          value={formData.pid}
          onChange={handleChange}
        />
      </div>

      <div className="input-group">
        <label htmlFor="customerName">Customer Name:</label>
        <input
          autoComplete="off"
          type="text"
          id="customerName"
          name="customerName"
          value={formData.customerName}
          onChange={handleChange}
        />
      </div>

      <div className="input-group">
        <label htmlFor="quantity">Number of Kits:</label>
        <input
          type="number"
          id="quantity"
          name="quantity"
          value={formData.quantity}
          onChange={handleChange}
        />
      </div>

      <div className="input-group">
        <label htmlFor="orderDate">Order Date:</label>
        <input
          autoComplete="off"
          type="date"
          id="orderDate"
          name="orderDate"
          value={formData.orderDate}
          onChange={handleChange}
        />
      </div>

      <div className="input-group">
        <label htmlFor="status">Order Status:</label>
        <input
          autoComplete="off"
          type="text"
          id="status"
          name="status"
          value={formData.status}
          onChange={handleChange}
        />
      </div>

      <button className="save-button" onClick={handleSubmit}>
        {isLoading ? <Spinner size="sm" /> : 'Create Order'}
      </button>
    </div>
  );
};

export default CreateOrderModal;

import React from 'react';

const PencilIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M7.46006 17.335L3.16475 13.0397M10.625 5.49991L15 9.8749M7.24112 17.3749H3.75C3.58424 17.3749 3.42527 17.3091 3.30806 17.1918C3.19085 17.0746 3.125 16.9157 3.125 16.7499V13.2588C3.125 13.1767 3.14117 13.0954 3.17258 13.0196C3.20398 12.9438 3.25002 12.8749 3.30806 12.8168L12.6831 3.44185C12.8003 3.32464 12.9592 3.25879 13.125 3.25879C13.2908 3.25879 13.4497 3.32464 13.5669 3.44185L17.0581 6.93296C17.1753 7.05017 17.2411 7.20914 17.2411 7.3749C17.2411 7.54066 17.1753 7.69964 17.0581 7.81685L7.68306 17.1918C7.62502 17.2499 7.55612 17.2959 7.48029 17.3273C7.40447 17.3587 7.32319 17.3749 7.24112 17.3749Z"
        stroke="#475569"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PencilIcon;

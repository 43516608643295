import React from 'react';

const RightArrowIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 8 40 40" fill="none">
      <g clipPath="url(#clip0_331_4372)">
        <path
          d="M17.705 14L16.295 15.41L20.875 20L16.295 24.59L17.705 26L23.705 20L17.705 14Z"
          fill="#475569"
        />
      </g>
      <defs>
        <clipPath id="clip0_331_4372">
          <rect width="40" height="40" rx="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RightArrowIcon;

import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  role: string;
}

interface UserDetailsModalProps {
  onSubmit: (data: Partial<FormData>) => void;
  isModalOpen: boolean;
  isLoading: boolean;
}

const CreateUserModal: React.FC<UserDetailsModalProps> = ({
  onSubmit,
  isModalOpen,
  isLoading = false,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
  } = useForm<FormData>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      role: 'Member',
    },
    mode: 'onChange',
  });

  const validatePasswordMatch = (value: string) => {
    const password = getValues('password'); // Get the password value
    return value === password || 'Passwords do not match';
  };

  useEffect(() => {
    reset();
  }, [isModalOpen]);

  const handleFormSubmit = (formData: FormData) => {
    onSubmit(formData);
    // reset(); // Reset the form after submission
  };

  return (
    <form className="input-fields" onSubmit={handleSubmit(handleFormSubmit)}>
      <h4>
        Create User
        <br />
        <span style={{ fontWeight: 400, fontSize: 14 }}>
          Enter user details and hit save button
        </span>
      </h4>

      <div className="input-group">
        <label htmlFor="firstName">First Name:</label>
        <input
          autoComplete="off"
          type="text"
          id="firstName"
          {...register('firstName', {
            required: 'First Name is required',
          })}
        />
      </div>

      <div className="input-group">
        <label htmlFor="lastName">Last Name:</label>
        <input
          autoComplete="off"
          type="text"
          id="lastName"
          {...register('lastName', {
            required: 'Last Name is required',
          })}
        />
      </div>

      <div className="input-group">
        <label htmlFor="email">Email:</label>
        <input
          autoComplete="off"
          type="text"
          id="email"
          {...register('email', {
            required: 'Email is required',
          })}
        />
      </div>

      <div className="input-group">
        <label htmlFor="password">Password:</label>
        <input
          autoComplete="off"
          type="password"
          id="password"
          {...register('password', {
            required: 'Password is required',
          })}
        />
        {errors.password && (
          <span className="error-text">{errors.password.message}</span>
        )}
      </div>

      <div className="input-group">
        <label htmlFor="confirmPassword">Confirm Password:</label>
        <input
          autoComplete="off"
          type="password"
          id="confirmPassword"
          {...register('confirmPassword', {
            required: 'Confirm Password is required',
            validate: validatePasswordMatch, // Use the custom validation function
          })}
        />
        {errors.confirmPassword && (
          <span className="error-text">{errors.confirmPassword.message}</span>
        )}
      </div>

      <div className="input-group">
        <label htmlFor="role">Role:</label>
        <select id="role" {...register('role')} className="select-field">
          <option value="Admin">Admin</option>
          <option value="Member">Member</option>
        </select>
      </div>

      <button type="submit" className="save-button">
        {isLoading ? <Spinner size="sm" /> : 'Submit'}
      </button>
    </form>
  );
};

export default CreateUserModal;

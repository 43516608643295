import React from 'react';

const PlusIcon = () => {
  return (
    <svg width="19" height="19" viewBox="0 0 23 22" fill="none">
      <path
        d="M6.875 10H13.125M10 6.875V13.125M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default PlusIcon;

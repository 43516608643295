import React from 'react';

const FrameIcon = () => {
  return (
    <svg width="20" height="20" viewBox="2 1 23 22" fill="none">
      <g clipPath="url(#clip0_187_423)">
        <path
          d="M8.33337 11.3332C8.33337 11.7752 8.50897 12.1991 8.82153 12.5117C9.13409 12.8242 9.55801 12.9998 10 12.9998C10.4421 12.9998 10.866 12.8242 11.1786 12.5117C11.4911 12.1991 11.6667 11.7752 11.6667 11.3332C11.6667 10.8911 11.4911 10.4672 11.1786 10.1547C10.866 9.8421 10.4421 9.6665 10 9.6665C9.55801 9.6665 9.13409 9.8421 8.82153 10.1547C8.50897 10.4672 8.33337 10.8911 8.33337 11.3332Z"
          stroke="#475569"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.2084 10.1248L12.9167 8.4165"
          stroke="#475569"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.33333 17.1667C4.11512 16.1984 3.22826 14.8752 2.79565 13.3804C2.36304 11.8856 2.4061 10.2933 2.91887 8.82405C3.43164 7.35482 4.38873 6.08147 5.65749 5.18046C6.92625 4.27945 8.44386 3.79541 10 3.79541C11.5561 3.79541 13.0737 4.27945 14.3425 5.18046C15.6113 6.08147 16.5684 7.35482 17.0811 8.82405C17.5939 10.2933 17.637 11.8856 17.2044 13.3804C16.7717 14.8752 15.8849 16.1984 14.6667 17.1667H5.33333Z"
          stroke="#475569"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_187_423">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FrameIcon;

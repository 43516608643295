import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import DashboardOrdersTable from '../components/order/DashboardOrdersTable';
import useOrderList from '../hooks/reactQuery/useOrderList';
import { Aggregates } from '../types/IOrderResponse';

interface IProps {
  search: string | undefined;
  setSearch: (s: string) => void;
  handleModalOpen: (data: any) => void;
  setAggregates: (data: Aggregates | undefined) => void;
  rowDetails: any;
  setRowDetails: any;
}
const ITEMS_LIMIT_PER_PAGE = 10;

const Dashboard = ({
  search = '',
  setSearch,
  handleModalOpen,
  setAggregates,
  rowDetails,
  setRowDetails,
}: IProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [customerName, setCustomerName] = useState('');
  const [pid, setPid] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [statuses, setStatuses] = useState([]);
  const [kitters, setKitters] = useState([]);
  const [selectedDate, setSelectedDate] = useState<Date | Date[] | null | any>(
    null,
  );

  const { data, isLoading, isFetching } = useOrderList({
    limit: ITEMS_LIMIT_PER_PAGE,
    page: currentPage,
    filter: {
      customerName,
      pid,
      status: statuses.length ? statuses.join('|') : undefined,
      kitter: kitters.length ? kitters.join('|') : undefined,
      orderDate: selectedDate,
      email,
      phone,
      section: 'Search',
    },
  });

  useEffect(() => {
    setAggregates(data?.pages?.[0]?.aggregates);
  }, [data, setAggregates]);

  return (
    <div className="main-content">
      {isLoading ? (
        <div className="spinner-position">
          <Spinner />
        </div>
      ) : (
        <DashboardOrdersTable
          data={data?.pages[0]}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          handleModalOpen={handleModalOpen}
          itemsPerPage={ITEMS_LIMIT_PER_PAGE}
          isFetching={isFetching}
          setCustomerName={setCustomerName}
          searchCustomerName={customerName}
          pid={pid}
          setPid={setPid}
          selectedKitters={kitters}
          setKitters={setKitters}
          setStatuses={setStatuses}
          selectedStatuses={statuses}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          rowDetails={rowDetails}
          setRowDetails={setRowDetails}
          email={email}
          setEmail={setEmail}
          phone={phone}
          setPhone={setPhone}
        />
      )}
    </div>
  );
};

export default Dashboard;

import React, { useRef, useState, useEffect } from 'react';
import SearchField from './searchField/CustomSearchField';
import DropDownFilter from './TableFilters/DropDownFilter';
import XCircleIcon from './icons/XCircleIcon';

interface IUserTableFilter {
  searchFirstName: string | undefined;
  setSearchFirstName: (e: any) => void;
  searchLastName: string | undefined;
  setSearchLastName: (e: any) => void;
  searchEmail: string | undefined;
  setSearchEmail: (e: any) => void;
  searchParams: any;
  setSelectedRoles: (s: string[]) => void;
  userRoles: string[];
  roleItems: string[];
  handleApplyRole: any;
}

const UserTableFilters: React.FC<IUserTableFilter> = ({
  searchFirstName,
  searchEmail,
  searchLastName,
  setSearchEmail,
  setSearchFirstName,
  setSearchLastName,
  setSelectedRoles,
  userRoles,
  roleItems,
  searchParams,
  handleApplyRole,
}) => {
  const [searchRole, setSearchRole] = useState<string>('');
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const handleClearSearch = (slug: any) => {
    if (slug === 'firstName') {
      setSearchFirstName('');
      searchParams.delete('firstName');
    }
    if (slug === 'lastName') {
      setSearchLastName('');
      searchParams.delete('lastName');
    }
    if (slug === 'email') {
      setSearchEmail('');
      searchParams.delete('email');
    }
  };

  const clearFilters = () => {
    setSearchEmail('');
    setSearchFirstName('');
    setSearchLastName('');
    setSearchRole('');
    setSelectedRoles([]);
    searchParams?.delete('email');
    searchParams?.delete('firstName');
    searchParams?.delete('lastName');
    searchParams?.delete('role');
    handleApplyRole([]);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <tr style={{ width: '88px', zIndex: 10000 }}>
      <th
        style={{
          width: '222.6px',
          height: '88px',
          backgroundColor: '#F1F5F9',
        }}
      >
        <SearchField
          search={searchFirstName}
          setSearch={setSearchFirstName}
          clearSearch={handleClearSearch}
          slug="firstName"
          isUserFilter
        />
      </th>
      <th
        style={{
          width: '222.6px',
          height: '88px',
          backgroundColor: '#F1F5F9',
        }}
      >
        <SearchField
          search={searchLastName}
          setSearch={setSearchLastName}
          clearSearch={handleClearSearch}
          slug="lastName"
          isUserFilter
        />
      </th>
      <th
        style={{
          width: '222.6px',
          height: '88px',
          backgroundColor: '#F1F5F9',
        }}
      >
        <SearchField
          search={searchEmail}
          setSearch={setSearchEmail}
          clearSearch={handleClearSearch}
          slug="email"
          isUserFilter
        />
      </th>
      <th
        style={{
          width: '222.6px',
          height: '105px',
          backgroundColor: '#F1F5F9',
        }}
      >
        <DropDownFilter
          searchItems={roleItems}
          handleApply={handleApplyRole}
          search={searchRole}
          setSearch={setSearchRole}
          selectedItems={userRoles}
          setSelectedItems={setSelectedRoles}
          dropdownOpen={dropdownOpen}
          setDropdownOpen={setDropdownOpen}
          dropdownRef={dropdownRef}
          isUserFilter
        />
      </th>
      <th
        style={{
          width: 'auto',
          height: '106px',
          backgroundColor: '#F1F5F9',
          display: 'flex',
        }}
      >
        <span
          style={{
            fontSize: '12px',

            display: 'flex',
            alignItems: 'center',
          }}
          className="clear-all-filters"
          role="button"
          onClick={clearFilters}
        >
          Clear All Filters
          <XCircleIcon />
        </span>
      </th>
    </tr>
  );
};
export default React.memo(UserTableFilters);

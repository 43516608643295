import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Spinner } from 'react-bootstrap';

interface IDeleteModal {
  show: boolean;
  onHide: () => void;
  onDelete: (id: any) => void;
  id: any;
  buttonType?: string;
  label?: string;
  isLoading?: boolean;
}

const DeleteModal: React.FC<IDeleteModal> = ({
  show,
  onHide,
  onDelete,
  id,
  buttonType = 'Delete',
  label = 'delete',
  isLoading = false,
}) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body>
        <Modal.Title>Are you sure you want to {label}?</Modal.Title>
        <div style={{ margin: 10 }} className="d-flex justify-content-end">
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => onDelete(id)}
            className="ml-2"
          >
            {isLoading ? <Spinner size="sm" /> : buttonType}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteModal;

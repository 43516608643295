import React, { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, Switch, Route } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import Users from './Users';
import Dashboard from './Dashboard';
import Sidebar from '../components/Sidebar';
import RightSideModal from '../components/modals/RightSideModal';
import OrderDetailsModal from '../components/modals/OrderDetailsUpdateModal';
import UserDetailsModal from '../components/modals/UserDetailsModal';
import UserProfileSettings from './Settings';
import useOrderUpdate from '../hooks/reactQuery/useUpdateOrder';
import useUserUpdate from '../hooks/reactQuery/useUpdateUser';
import CreateOrderModal from '../components/modals/CreateOrderModal';
import useCreateOrder from '../hooks/reactQuery/useCreateOrder';
import useCreateUser from '../hooks/reactQuery/useCreateUser';
import CreateUserModal from '../components/modals/CreateUserModal';
import useChangePassword from '../hooks/reactQuery/useChangeUserPassword';
import EditPassword from '../components/modals/EditPasswordModal';
import NewOrders from './NewOrders';
import KittingOrders from './KittingOrders';
import DeliveredOrders from './DeliveredOrders';
import ShippingOrders from './ShippingOrders';
import useGetOrderCount from '../hooks/reactQuery/useGetOrdersCount';
import pagesSlug from '../constant/pages-slug';
import UpdateUserPassword from '../components/modals/UpdateUserPasswordModal';
import useChangePasswordByAdmin from '../hooks/reactQuery/useChangeUserPasswordByAdmin';
import useGetOrderDetailsByNextStatus from '../hooks/reactQuery/useGetOrderDetailsByNextStatus';

interface TableRowState {
  class: string;
  orderId: number;
  isActive: boolean;
}

const getTitle = (slug: string) => {
  if (slug === pagesSlug.users) {
    return 'User Management';
  }
  if (slug === pagesSlug.settings) {
    return 'Settings';
  }
  if (slug === pagesSlug.newOrders) {
    return 'New Orders';
  }
  if (slug === pagesSlug.shipping) {
    return 'Shipping Orders';
  }
  if (slug === pagesSlug.delivered) {
    return 'Order in Delivery';
  }
  if (slug === pagesSlug.kitting) {
    return 'Product Kitting Orders';
  }
  return 'Search';
};

const getMenuItem = (path: string) => {
  if (path.includes('users')) {
    return pagesSlug.users;
  }
  if (path.includes('settings')) {
    return pagesSlug.settings;
  }
  if (path.includes('new-orders')) {
    return pagesSlug.newOrders;
  }
  if (path.includes('kitting-orders')) {
    return pagesSlug.kitting;
  }
  if (path.includes('shipping-orders')) {
    return pagesSlug.shipping;
  }
  if (path.includes('delivery-orders')) {
    return pagesSlug.delivered;
  }
  return 'dashboard';
};

const getModal = (
  modalType: string | undefined,
  saveData: (data: any) => void,
  data: any,
  isModalOpen: boolean,
  isLoading: boolean,
  setIsCancelModalOpen: (e: boolean) => void,
) => {
  if (modalType === 'order') {
    return (
      <OrderDetailsModal
        onSubmit={saveData}
        orderData={data}
        isLoading={isLoading}
        setIsCancelModalOpen={setIsCancelModalOpen}
      />
    );
  }
  if (modalType === 'user') {
    return (
      <UserDetailsModal onSubmit={saveData} data={data} isLoading={isLoading} />
    );
  }
  if (modalType === 'update-user-password') {
    return (
      <UpdateUserPassword
        onSubmit={saveData}
        data={data}
        isModalOpen={isModalOpen}
        isLoading={isLoading}
      />
    );
  }
  if (modalType === 'create-user') {
    return (
      <CreateUserModal
        onSubmit={saveData}
        isModalOpen={isModalOpen}
        isLoading={isLoading}
      />
    );
  }
  if (modalType === 'create-order') {
    return <CreateOrderModal onSubmit={saveData} isLoading={isLoading} />;
  }
  if (modalType === 'settings') {
    return (
      <EditPassword
        onSubmit={saveData}
        data={data}
        isModalOpen={isModalOpen}
        isLoading={isLoading}
      />
    );
  }
  return null;
};

const Auth: React.FC = () => {
  const location = useLocation();
  const { user, isAuthenticated, logout } = useAuth0();
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [search, setSearch] = useState<string>();
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>('dashboard');
  const [modalType, setModalType] = useState<undefined | string>('');
  const [modalData, setModalData] = useState<any>({});
  const [aggregates, setAggregates] = useState<any>({});
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const { mutate: updateUser, isLoading: isUpdateUserLoading } =
    useUserUpdate(undefined);
  const { mutate: createOrder, isLoading: isCreateOrderLoading } =
    useCreateOrder();
  const { mutate: createUser, isLoading: isCreateUserLoading } =
    useCreateUser();
  const { mutate: changePassword, isLoading: isChangePasswordLoading } =
    useChangePassword();
  const { data: orderCountData, isLoading: isGetOrderCountLoading } =
    useGetOrderCount();
  const { mutate: changeUserPassword, isLoading: isChangeUserPasswordLoading } =
    useChangePasswordByAdmin();

  const isloading =
    isUpdateUserLoading ||
    isCreateOrderLoading ||
    isChangePasswordLoading ||
    isCreateUserLoading ||
    isChangeUserPasswordLoading ||
    isGetOrderCountLoading;

  const [tableRowClass, setTableRowClass] = useState<TableRowState | null>(
    null,
  );

  const handleMenuItemClick = (menuItem: string) => {
    if (menuItem === 'logout') {
      logout();
    }
    setSelectedMenuItem(menuItem);
  };

  const [showSidebar, setShowSidebar] = useState(window.innerWidth >= 980);

  useEffect(() => {
    setSelectedMenuItem(getMenuItem(location.pathname));
    const handleResize = () => {
      setShowSidebar(window.innerWidth >= 980);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = (data: any) => {
    setModalData(data?.data);
    setModalType(data?.modal);
    if (data?.isRow) {
      setIsModalOpen(false);
    }
    if (
      tableRowClass &&
      tableRowClass.orderId === data?.data?.id &&
      tableRowClass.isActive
    ) {
      setIsModalOpen(false);
      setTableRowClass({
        class: 'table-default',
        orderId: data?.data?.id,
        isActive: false,
      });
    } else {
      setIsModalOpen(true);
    }
  };

  const handleModalClose = (
    data: any,
    isCloseButton = false,
    isOutSide = false,
  ) => {
    if (isCloseButton) {
      setTableRowClass({ class: '', isActive: false, orderId: 0 });
      setIsModalOpen(false);
    } else {
      setIsModalOpen(false);
    }
  };

  const saveData = (data: any) => {
    if (modalType === 'user') {
      updateUser(data, {
        onSuccess: () => {
          toast.success(`User Updated`);
          setIsModalOpen(false);
        },
        onError: (error) => {
          toast.error(error?.Message || `Failed to update`);
        },
      });
    }
    if (modalType === 'update-user-password') {
      changeUserPassword(data, {
        onSuccess: () => {
          toast.success(`User Password Updated`);
          setIsModalOpen(false);
        },
        onError: (error) => {
          toast.error(error?.Message || `Failed to update`);
        },
      });
    }
    if (modalType === 'create-user') {
      createUser(data, {
        onSuccess: () => {
          toast.success(`User Created`);
          setIsModalOpen(false);
        },
        onError: (error) => {
          toast.error(error?.Message || `Failed to create`);
        },
      });
    }
    if (modalType === 'create-order') {
      createOrder(data, {
        onSuccess: () => {
          toast.success(`Order Created`);
          setIsModalOpen(false);
        },
        onError: (error) => {
          toast.error(error?.Message || `Failed to create`);
        },
      });
    }
    if (modalType === 'settings') {
      changePassword(data, {
        onSuccess: () => {
          toast.success(`Password updated`);
          setIsModalOpen(false);
        },
        onError: (error) => {
          toast.error(error?.Message || `Failed to update`);
        },
      });
    }
  };
  return isAuthenticated && user ? (
    <div className="dashboard-container">
      <RightSideModal
        modal={modalType}
        isOpen={isModalOpen}
        onClose={handleModalClose}
        data={modalData}
        isOtherModalOpen={isCancelModalOpen}
        setRowClass={setTableRowClass}
      >
        {getModal(
          modalType,
          saveData,
          modalData,
          isModalOpen,
          isloading,
          setIsCancelModalOpen,
        )}
      </RightSideModal>
      <Sidebar
        showSidebar={showSidebar}
        selectedMenuItem={selectedMenuItem}
        onMenuItemClick={handleMenuItemClick}
        aggregates={orderCountData}
      />

      <div className="dashboard-area">
        <Navbar bg="light" expand="lg" className="top-nav">
          <h4 style={{ fontWeight: 700 }}>{getTitle(selectedMenuItem)}</h4>
          {/* <Form className="ml-auto mr-3">
            <div style={{ position: 'relative' }}>
              {!search ? (
                <span
                  style={{
                    position: 'absolute',
                    top: '35%',
                    left: '10px',
                    transform: 'translateY(-50%)',
                    width: '16px',
                    height: '16px',
                  }}
                >
                  <SearchIcon />
                </span>
              ) : null}
              <FormControl
                type="text"
                placeholder="  Search..."
                className="mr-sm-2"
                style={{
                  borderRadius: 20,
                  paddingLeft: '25px',
                }}
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
            </div>
          </Form>
          <div
            className="profile-icon-container"
            onClick={() => setShowSubMenu(!showSubMenu)}
            role="button"
          >
            <Image
              src=""
              roundedCircle
              style={{ width: '40px', height: '40px' }}
            />
            {showSubMenu && (
              <div className="sub-menu">
                <div className="sub-menu-item">Profile</div>
                <div className="sub-menu-item">Settings</div>
                <div className="sub-menu-item">Logout</div>
              </div>
            )}
          </div> */}
          <div className="ml-3">
            {window.innerWidth >= 980 ? null : (
              <FontAwesomeIcon
                icon={faBars}
                className="me-2 toggle-button"
                onClick={toggleSidebar}
              />
            )}
          </div>
        </Navbar>
        <Switch>
          <Route key="new-orders" path="/new-orders">
            <NewOrders
              search={search}
              setSearch={setSearch}
              handleModalOpen={handleModalOpen}
              rowDetails={tableRowClass}
              setRowDetails={setTableRowClass}
            />
          </Route>
          <Route path="/kitting-orders">
            <KittingOrders
              search={search}
              setSearch={setSearch}
              handleModalOpen={handleModalOpen}
              rowDetails={tableRowClass}
              setRowDetails={setTableRowClass}
            />
          </Route>
          <Route path="/delivery-orders">
            <DeliveredOrders
              search={search}
              setSearch={setSearch}
              handleModalOpen={handleModalOpen}
              rowDetails={tableRowClass}
              setRowDetails={setTableRowClass}
            />
          </Route>
          <Route path="/shipping-orders">
            <ShippingOrders
              search={search}
              setSearch={setSearch}
              handleModalOpen={handleModalOpen}
              rowDetails={tableRowClass}
              setRowDetails={setTableRowClass}
            />
          </Route>
          <Route path="/users">
            <Users
              search={search}
              setSearch={setSearch}
              handleModalOpen={handleModalOpen}
            />
          </Route>
          <Route path="/settings">
            <UserProfileSettings handleModalOpen={handleModalOpen} />
          </Route>
          <Route exact key="dashboard" path="/">
            <Dashboard
              search={search}
              setSearch={setSearch}
              handleModalOpen={handleModalOpen}
              setAggregates={setAggregates}
              rowDetails={tableRowClass}
              setRowDetails={setTableRowClass}
            />
          </Route>
        </Switch>
      </div>
    </div>
  ) : null;
};

export default Auth;

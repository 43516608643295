import React from 'react';

const TrashIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M11.8751 8.625V13.625M8.12506 8.625V13.625M16.8748 4.875L3.12476 4.87501M15.6251 4.875V16.75C15.6251 16.9158 15.5592 17.0747 15.442 17.1919C15.3248 17.3092 15.1658 17.375 15.0001 17.375H5.00006C4.8343 17.375 4.67533 17.3092 4.55812 17.1919C4.44091 17.0747 4.37506 16.9158 4.37506 16.75V4.875M13.1251 4.875V3.625C13.1251 3.29348 12.9934 2.97554 12.7589 2.74112C12.5245 2.5067 12.2066 2.375 11.8751 2.375H8.12506C7.79354 2.375 7.4756 2.5067 7.24118 2.74112C7.00676 2.97554 6.87506 3.29348 6.87506 3.625V4.875"
        stroke="#475569"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrashIcon;

import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import useOrderList from '../hooks/reactQuery/useOrderList';
import OrdersTable from '../components/order/OrdersTable';

interface IProps {
  search: string | undefined;
  setSearch: (s: string) => void;
  handleModalOpen: (data: any) => void;
  rowDetails: any;
  setRowDetails: any;
}
const ITEMS_LIMIT_PER_PAGE = 10;

const ShippingOrders = ({
  search = '',
  setSearch,
  handleModalOpen,
  rowDetails,
  setRowDetails,
}: IProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading, isFetching } = useOrderList({
    limit: ITEMS_LIMIT_PER_PAGE,
    page: currentPage,
    filter: {
      section: 'Order in Shipping',
    },
  });

  return (
    <div className="main-content">
      {isLoading ? (
        <div className="spinner-position">
          <Spinner />
        </div>
      ) : (
        <OrdersTable
          data={data?.pages[0]}
          search={search}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setSearch={setSearch}
          handleModalOpen={handleModalOpen}
          itemsPerPage={ITEMS_LIMIT_PER_PAGE}
          isFetching={isFetching}
          orderType="Order Shipping List"
          rowDetails={rowDetails}
          setRowDetails={setRowDetails}
        />
      )}
    </div>
  );
};

export default ShippingOrders;

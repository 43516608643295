import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Spinner, Tab, Tabs } from 'react-bootstrap';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { toast } from 'react-toastify';
import { IKitter, IOrderItem } from '../../types/IOrderResponse';
import useOrderDetails from '../../hooks/reactQuery/useOrderDetails';
import DownloadIcon from '../icons/DownloadIcon';
import useGetAllKitters from '../../hooks/reactQuery/useGetAllKitters';
import PdfDocument from '../pdf/RenderFlashUrlPdf';
import CancelIcon from '../icons/CancelIcon';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import useDeleteOrder from '../../hooks/reactQuery/useDeleteOrder';
import useGetOrderDetailsByNextStatus from '../../hooks/reactQuery/useGetOrderDetailsByNextStatus';
import useOrderUpdate from '../../hooks/reactQuery/useUpdateOrder';
import getRandomColor from '../../utils/getRandomColor';
import getRandomClass from '../../utils/getRandomStatusClass';

interface OrderDetailsModalProps {
  onSubmit: (data: IOrderItem) => void;
  orderData: (IOrderItem & { kitter?: IKitter }) | any;
  isLoading: boolean;
  setIsCancelModalOpen: any;
}

const OrderDetailsModal: React.FC<OrderDetailsModalProps> = ({
  onSubmit,
  orderData,
  isLoading,
  setIsCancelModalOpen,
}) => {
  const { data, isFetching } = useOrderDetails(orderData?.id);
  const { register, handleSubmit, setValue, reset } = useForm<
    { kitter: IKitter } & IOrderItem & any
  >({
    defaultValues: data || {},
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [key, setKey] = useState<string>('tab1');
  const { data: allKitters } = useGetAllKitters();
  const { mutate: updateOrder, isLoading: isUpdateOrderLoading } =
    useOrderUpdate(orderData?.id);
  const { mutate: deleteOrder, isLoading: isDeleteLoading } = useDeleteOrder(
    data?.id,
  );
  const {
    mutate: updateNextStatus,
    data: orderNextStatusDetails,
    isLoading: isNextStatusLoading,
  } = useGetOrderDetailsByNextStatus();

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  useEffect(() => {
    if (orderNextStatusDetails) {
      reset(orderNextStatusDetails);
    }
  }, [orderNextStatusDetails, reset]);

  const handleCheckboxChange = (checked: boolean) => {
    if (checked) {
      const billingAddress = data?.billingAddress;
      setValue('shippingAddress', { ...billingAddress });
    } else {
      setValue('shippingAddress', {
        name: '',
        businessName: '',
        streetAddress: '',
        unitSuite: '',
        city: '',
        province: '',
        postalCode: '',
      });
    }
  };

  const handleDelete = (orderId: number) => {
    deleteOrder(
      { orderId },
      {
        onSuccess: () => {
          toast.success(`Order Cancelled`);
          setIsModalOpen(false);
        },
        onError: () => {
          toast.error(`Failed to delete`);
        },
      },
    );
    setTimeout(() => setIsCancelModalOpen(false), 1000);
  };

  const handleFormSubmit = (formData: IOrderItem) => {
    updateOrder(formData, {
      onSuccess: () => {
        toast.success(`Order Updated`);
      },
      onError: (error) => {
        toast.error(error?.Message || `Failed to update`);
      },
    });
  };

  const handleStatusChange = () => {
    updateNextStatus({ id: orderData?.id });
  };

  const isAdmin = data?.permissions?.Self?.includes('admin-section');

  if (isLoading || isFetching) {
    return (
      <div className="spinner-position-modal">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className="horizontal-tabs">
        <span
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 5,
            alignItems: 'center',
            height: '60px',
          }}
        >
          <span>
            <h4>
              Order Details
              <br />
            </h4>
          </span>
          <span>
            <div className="input-group mt-4 mb-3">
              <label htmlFor="flashTitleUrl">
                Flash Title:
                <span
                  style={{ float: 'right', marginLeft: 10 }}
                  role="button"
                  onClick={() => {}}
                >
                  <PDFDownloadLink
                    document={
                      <PdfDocument
                        customerName={orderData?.customerName}
                        kitter={data?.kitter.name}
                        quantity={data?.quantity}
                      />
                    }
                    fileName={`FTC_${data?.pid}.pdf`}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? <Spinner size="sm" /> : <DownloadIcon />
                    }
                  </PDFDownloadLink>
                </span>
              </label>
            </div>
          </span>
        </span>
        <span>
          <span className="pid-icon">{data?.pid}</span>
          {data?.kitter?.name?.length > 18 ? (
            <>
              <br /> <br />
            </>
          ) : null}
          <span className="kitter-icon">{data?.kitter?.name}</span>
          {(data?.kitter?.name?.length || 0) + (data?.status?.length || 0) >
            28 || data?.status?.length > 18 ? (
            <>
              <br /> <br />
            </>
          ) : null}
          <span className={getRandomClass(data?.status)}>{data?.status}</span>
        </span>
        <Tabs
          id="controlled-horizontal-tabs"
          activeKey={key}
          onSelect={(k) => setKey(k || 'tab1')}
        >
          <Tab eventKey="tab1" title="Order Details">
            {/* <h4>
              Details
              <br />
            </h4> */}

            {/* <div className="input-group">
              <label htmlFor="status">Status:</label>
              <input type="text" id="status" readOnly {...register('status')} />
            </div>

            <div className="input-group">
              <label htmlFor="kitter">Kitter:</label>
              <input
                type="text"
                id="kitter.name"
                readOnly
                {...register('kitter.name')}
              />
            </div> */}

            <div className="input-group">
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" readOnly {...register('name')} />
            </div>

            <div className="input-group">
              <label htmlFor="pid">PID:</label>
              <input
                type="text"
                id="pid"
                readOnly
                className="border-radius"
                {...register('pid')}
              />
            </div>

            <div className="input-group">
              <label htmlFor="phoneNumber">Phone Number:</label>
              <input
                type="text"
                id="phoneNumber"
                readOnly
                {...register('phoneNumber')}
              />
            </div>

            <div className="input-group">
              <label htmlFor="emailAddress">Email Address:</label>
              <input
                type="text"
                id="emailAddress"
                readOnly
                {...register('emailAddress')}
              />
            </div>

            <div className="input-group">
              <label htmlFor="quantity">Quantity:</label>
              <input
                type="number"
                id="quantity"
                readOnly
                {...register('quantity')}
              />
            </div>

            {data?.nextStatus ? (
              <button
                className="save-button"
                onClick={() => handleStatusChange()}
              >
                {isNextStatusLoading ? <Spinner size="sm" /> : data?.nextStatus}
              </button>
            ) : null}
          </Tab>
          <Tab eventKey="tab2" title="Shipping Address">
            {/* <h4>Shipping Address:</h4> */}
            <div className="input-group">
              <label htmlFor="shippingAddress.name">Name:</label>
              <input
                autoComplete="off"
                readOnly
                type="text"
                id="shippingAddress.name"
                {...register('shippingAddress.name')}
              />
            </div>

            <div className="input-group">
              <label htmlFor="shippingAddress.businessName">
                Business Name:
              </label>
              <input
                autoComplete="off"
                readOnly
                type="text"
                id="shippingAddress.businessName"
                {...register('shippingAddress.businessName')}
              />
            </div>

            <div className="input-group">
              <label htmlFor="shippingAddress.streetAddress">
                Street Address:
              </label>
              <input
                autoComplete="off"
                readOnly
                type="text"
                id="shippingAddress.streetAddress"
                {...register('shippingAddress.streetAddress')}
              />
            </div>

            <div className="input-group">
              <label htmlFor="shippingAddress.unitSuite">Unit Suite:</label>
              <input
                autoComplete="off"
                readOnly
                type="text"
                id="shippingAddress.unitSuite"
                {...register('shippingAddress.unitSuite')}
              />
            </div>

            <div className="input-group">
              <label htmlFor="shippingAddress.city">City:</label>
              <input
                autoComplete="off"
                readOnly
                type="text"
                id="shippingAddress.city"
                {...register('shippingAddress.city')}
              />
            </div>

            <div className="input-group">
              <label htmlFor="shippingAddress.province">Province:</label>
              <input
                autoComplete="off"
                readOnly
                type="text"
                id="shippingAddress.province"
                {...register('shippingAddress.province')}
              />
            </div>

            <div className="input-group">
              <label htmlFor="shippingAddress.postalCode">Postal Code:</label>
              <input
                autoComplete="off"
                readOnly
                type="text"
                id="shippingAddress.postalCode"
                {...register('shippingAddress.postalCode')}
              />
            </div>

            {data?.nextStatus ? (
              <button
                className="save-button"
                onClick={() => handleStatusChange()}
              >
                {isNextStatusLoading ? <Spinner size="sm" /> : data?.nextStatus}
              </button>
            ) : null}
          </Tab>
          <Tab eventKey="tab3" title="Admin">
            <form key="admin" onSubmit={handleSubmit(handleFormSubmit)}>
              {isAdmin ? (
                <>
                  {/* <h4>Admin Section:</h4> */}
                  <div className="input-group">
                    <label htmlFor="status">Status:</label>
                    <select
                      id="status"
                      {...register('status')}
                      className="select-field"
                    >
                      {data?.permissions?.StatusList?.map(
                        (status: string, index: number) => (
                          <option key={`key-${status}-${index}`} value={status}>
                            {status}
                          </option>
                        ),
                      )}
                    </select>
                  </div>
                  <div className="input-group">
                    <label htmlFor="status">Kitting:</label>
                    <select
                      id="kitting"
                      {...register('kitter.id')}
                      className="select-field"
                    >
                      {allKitters?.map((kitter: IKitter, index: number) => (
                        <option
                          key={`key-${kitter.id}-${index}`}
                          value={kitter.id}
                        >
                          {kitter.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              ) : null}

              {/* <div className="input-group">
        <label
          htmlFor="shippingAddress.sameAsBillingAddress"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <input
            type="checkbox"
            id="shippingAddress.sameAsBillingAddress"
            {...register('shippingAddress.sameAsBillingAddress', {
              onChange: handleCheckboxChange,
            })}
          />
          <span style={{ marginLeft: '5px' }}>Same as Billing Address</span>
        </label>
      </div> */}

              <div className="input-group mt-4 mb-3">
                <label htmlFor="cancelOrder">
                  Cancel Order:
                  <span
                    style={{ float: 'right' }}
                    role="button"
                    onClick={() => {
                      setIsCancelModalOpen(true);
                      setIsModalOpen(true);
                    }}
                  >
                    <CancelIcon />
                  </span>
                </label>
              </div>

              <button type="submit" className="save-button">
                {isUpdateOrderLoading ? <Spinner size="sm" /> : 'Save'}
              </button>
            </form>
          </Tab>
        </Tabs>
      </div>
      <DeleteConfirmationModal
        onDelete={handleDelete}
        onHide={() => {
          setIsModalOpen(false);
          setTimeout(() => setIsCancelModalOpen(false), 1000);
        }}
        show={isModalOpen}
        id={data?.id}
        label="cancel"
        buttonType="Cancel Order"
        isLoading={isDeleteLoading}
      />
    </>
  );
};

export default OrderDetailsModal;

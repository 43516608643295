import React from 'react';
import { Spinner } from 'react-bootstrap';
import './styles/App.css';
import { useAuth0 } from '@auth0/auth0-react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import Login from './components/Login';
import 'react-toastify/dist/ReactToastify.css';
import Logout from './components/Logout';

function App() {
  const { isLoading } = useAuth0();

  if (isLoading)
    return (
      <div className="spinner-position">
        <Spinner />
      </div>
    );

  return (
    <>
      <BrowserRouter>
        <Logout />
      </BrowserRouter>
      <Login />
      <ToastContainer
        position="top-right"
        theme="light"
        hideProgressBar
        pauseOnHover
        autoClose={4000}
        draggable
      />
    </>
  );
}

export default App;

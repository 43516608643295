import moment from 'moment';

function convertUtcToLocal(utcTimestamp: string | undefined) {
  if (!utcTimestamp) {
    return utcTimestamp;
  }
  const localDateTime = moment.utc(utcTimestamp).local();
  return localDateTime.format('YYYY-MM-DD HH:mm:ss'); // Format the result as desired
}

export default convertUtcToLocal;

import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import logoUrl from '../constant/logo-url';
import UserIcon from './icons/UserIcon';
import SignoutIcon from './icons/SignoutIcon';
import GearSixIcon from './icons/GearSixIcon';
import FrameIcon from './icons/FrameIcon';
import PackageIcon from './icons/PackageIcon';
import Frame1Icon from './icons/Frame1Icon';
import ListIcon from './icons/ListIcon';
import ShippingIcon from './icons/ShippingIcon';
import NotificationIcon from './icons/NotificationIcon';
import useMenuItems from '../hooks/reactQuery/useMenuItems';
import pagesSlug from '../constant/pages-slug';

interface Aggregates {
  [key: string]: number;
}

type IProps = {
  showSidebar: boolean;
  selectedMenuItem: string;
  onMenuItemClick: any;
  aggregates: Aggregates;
};

const Sidebar = ({
  showSidebar = false,
  selectedMenuItem,
  onMenuItemClick,
  aggregates,
}: IProps) => {
  const { data: allMenuItems, isLoading } = useMenuItems();
  const menuItems = [
    {
      icon: <FrameIcon />,
      label: 'Search',
      href: '/',
      slug: pagesSlug.dashboard,
      notification: false,
      key: 'Orders',
    },
    {
      icon: <PackageIcon />,
      label: 'New Orders',
      href: '/new-orders',
      slug: pagesSlug.newOrders,
      notification: true,
      notificationColor: '#475569',
      key: 'Orders',
    },
    {
      icon: <Frame1Icon />,
      label: 'Product Kitting',
      href: '/kitting-orders',
      slug: pagesSlug.kitting,
      notification: true,
      notificationColor: '#D97706',
      key: 'Orders',
    },
    {
      icon: <ShippingIcon />,
      label: 'Order in Shipping',
      href: '/shipping-orders',
      slug: pagesSlug.shipping,
      notification: true,
      notificationColor: '#059669',
      key: 'Orders',
    },
    {
      icon: <ListIcon />,
      label: 'Order in Delivery',
      href: '/delivery-orders',
      slug: pagesSlug.delivered,
      notification: true,
      notificationColor: '#2563EB',
      key: 'Orders',
    },
    {
      icon: <UserIcon />,
      label: 'Users',
      href: '/users',
      slug: pagesSlug.users,
      notification: false,
      key: 'Users',
    },
    {
      icon: <GearSixIcon />,
      label: 'Settings',
      href: '/settings',
      slug: pagesSlug.settings,
      notification: false,
      key: 'Settings',
    },
    {
      icon: <SignoutIcon />,
      label: 'Logout',
      href: '#',
      slug: pagesSlug.logout,
      notification: false,
      key: 'Logout',
    },
  ];
  const displayMenuItems = useMemo(() => {
    const filteredMenu: string[] = allMenuItems?.pages || [];
    if (!allMenuItems?.pages.length) return [];
    filteredMenu.push('Logout');

    const displayMenu = menuItems.filter((menu) =>
      filteredMenu.includes(menu.key),
    );

    return displayMenu;
  }, [allMenuItems?.pages]);

  return (
    <div
      className={`sidebar bg-white ${
        showSidebar ? 'open-sidebar' : 'collapsed-sidebar'
      }`}
    >
      <img src={logoUrl} alt="logo" className="logo" />
      <ul className="nav flex-column">
        {displayMenuItems?.map((item, index) => (
          <React.Fragment key={index}>
            <li className="nav-item">
              <NavLink
                to={item.href}
                className={`text-decoration-none menu-item ${
                  showSidebar ? 'open-sidebar-items' : 'collapsed-sidebar-items'
                } `}
                onClick={() => onMenuItemClick(item.slug)}
              >
                <span
                  className={`nav-link p-3 ${
                    selectedMenuItem === item.slug ? 'active-item' : ''
                  }`}
                >
                  {item.icon}
                  <span style={{ paddingLeft: 3 }}>{item.label}</span>
                  {item.notification && aggregates && aggregates[item.slug] ? (
                    <NotificationIcon
                      count={aggregates[item.slug]}
                      color={item.notificationColor}
                    />
                  ) : null}
                </span>
              </NavLink>
            </li>
            {(index === 4 || index === 0) && (
              <hr
                className="mx-3 mt-5"
                style={{ display: showSidebar ? 'inline' : 'none' }}
              />
            )}
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};

export default React.memo(Sidebar);

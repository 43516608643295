import React from 'react';

const FilterIcon = () => {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none">
      <g clipPath="url(#clip0_331_2981)">
        <path
          d="M2.19995 2H10.2V3.086C10.1999 3.35119 10.0945 3.60551 9.90695 3.793L7.69995 6V9.5L4.69995 10.5V6.25L2.45995 3.786C2.29267 3.60196 2.19998 3.3622 2.19995 3.1135V2Z"
          stroke="#64748B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_331_2981">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0.199951)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FilterIcon;

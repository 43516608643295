import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import './index.css';
import Auth0ProviderWithHistory from './Auth0ProviderWithHistory';

const root = createRoot(document.getElementById('root')!);
const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <Auth0ProviderWithHistory>
      <App />
    </Auth0ProviderWithHistory>
  </QueryClientProvider>,
);

import React from 'react';

const CalendarIcon = () => {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none">
      <path
        d="M4.17505 1.5V2.625M9.42505 1.5V2.625M2.30005 9.375V3.75C2.30005 3.12868 2.80373 2.625 3.42505 2.625H10.175C10.7964 2.625 11.3 3.12868 11.3 3.75V9.375M2.30005 9.375C2.30005 9.99632 2.80373 10.5 3.42505 10.5H10.175C10.7964 10.5 11.3 9.99632 11.3 9.375M2.30005 9.375V5.625C2.30005 5.00368 2.80373 4.5 3.42505 4.5H10.175C10.7964 4.5 11.3 5.00368 11.3 5.625V9.375"
        stroke="#64748B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalendarIcon;

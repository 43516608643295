import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react';

interface UserProfileProps {
  handleModalOpen: (data: any) => void;
}

const UserProfileSettings: React.FC<UserProfileProps> = ({
  handleModalOpen,
}) => {
  const { user: loggedInUser } = useAuth0();
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    password: '',
    oldPassword: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  return (
    <div className="main-content">
      <div
        style={{
          backgroundColor: '#ffffff',
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        <h4 style={{ borderBottom: 2, marginBottom: '20px' }}>Settings</h4>
        <div className="setting-form">
          <div className="setting-group">
            <label className="setting-label" htmlFor="firstName">
              Name:
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="setting-input"
              value={loggedInUser?.nickname || loggedInUser?.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="setting-group">
            <label className="setting-label" htmlFor="lastName">
              Email:
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              className="setting-input"
              readOnly
              value={loggedInUser?.email}
              onChange={handleInputChange}
            />
          </div>
          <button
            className="setting-button"
            onClick={() => handleModalOpen({ modal: 'settings' })}
          >
            Edit Password
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserProfileSettings;

import React from 'react';
import { CloseButton, Form, FormControl } from 'react-bootstrap';
import SearchIcon from '../icons/SearchIcon';

interface ISearchProps {
  search: string | undefined;
  setSearch: (s: string) => void;
  clearSearch: (e: any) => void;
  slug: string;
  isUserFilter?: boolean;
}

const SearchField: React.FC<ISearchProps> = ({
  search,
  setSearch,
  clearSearch,
  slug,
  isUserFilter = false,
}) => {
  return (
    <Form className="ml-auto">
      <div style={{ position: 'relative' }}>
        <span
          style={{
            position: 'absolute',
            top: '35%',
            left: '10px',
            transform: 'translateY(-50%)',
            width: '16px',
            height: '16px',
          }}
        >
          <SearchIcon width="12" height="12" />
        </span>
        <span
          style={{
            position: 'absolute',
            top: '45%',
            left: isUserFilter && window.innerWidth >= 980 ? '75%' : '84%',
            transform: 'translateY(-50%)',
            height: '16px',
            fontSize: '10px',
            float: 'right',
          }}
          role="button"
          onClick={() => clearSearch(slug)}
        >
          <CloseButton />
        </span>
        <FormControl
          type="text"
          placeholder="Search"
          style={{
            width: isUserFilter ? '222.6px' : '170.6px',
            height: '34px',
            fontSize: '12px',
            borderRadius: '48px',
            padding: '1px 25px',
            border: '1px solid #475569',
            backgroundColor: 'white',
            paddingLeft: '29px',
            marginBottom: '20px',
            marginRight: '8px',
          }}
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
      </div>
    </Form>
  );
};
export default SearchField;

/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from 'react';
import SearchField from './searchField/CustomSearchField';
import CalendarFilter from './TableFilters/CalendarFilter';
import DropDownFilter from './TableFilters/DropDownFilter';
import XCircleIcon from './icons/XCircleIcon';

interface IOrderTableFilter {
  searchCustomerName: string | undefined;
  setSearchCustomerName: (e: any) => void;
  searchPid: string | undefined;
  setSearchPid: (e: any) => void;
  kitterItems: string[];
  handleKitterApply: (e: any, z?: any) => void;
  statusItems: string[];
  handleStatusApply: (e: any) => void;
  selectedDate: Date | Date[] | null | any;
  setSelectedDate: (e: Date | Date[] | null | any) => void;
  searchParams: any;
  handleClearFilters: any;
  selectedStatusItems: string[];
  selectedKitterItems: string[];
  setSelectedStatusItems: (s: string[]) => void;
  setSelectedKitterItems: (s: string[]) => void;
  email: string | undefined;
  setEmail: (e: any) => void;
  phone: string | undefined;
  setPhone: (e: any) => void;
}

const OrderTableFilters: React.FC<IOrderTableFilter> = ({
  searchCustomerName,
  searchPid,
  statusItems,
  handleKitterApply,
  handleStatusApply,
  kitterItems,
  setSearchPid,
  setSearchCustomerName,
  selectedDate,
  setSelectedDate,
  searchParams,
  handleClearFilters,
  selectedKitterItems,
  selectedStatusItems,
  setSelectedKitterItems,
  setSelectedStatusItems,
  email,
  setEmail,
  phone,
  setPhone,
}) => {
  const [searchKitter, setSearchKitter] = useState<string>('');
  const [searchStatus, setSearchStatus] = useState<string>('');
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [isClearDate, setIsClearDate] = useState<boolean>(false);
  const [kitterDropdownOpen, setKitterDropdownOpen] = useState<boolean>(false);
  const handleClearSearch = (slug: any) => {
    if (slug === 'customerName') {
      setSearchCustomerName('');
      searchParams.delete('customerName');
    }
    if (slug === 'pid') {
      setSearchPid('');
      searchParams.delete('pid');
    }
    if (slug === 'email') {
      setEmail('');
      searchParams.delete('email');
    }
    if (slug === 'phone') {
      setPhone('');
      searchParams.delete('phone');
    }
  };

  const clearFilters = () => {
    setIsClearDate(true);
    setSearchKitter('');
    setSearchStatus('');
    setSelectedKitterItems([]);
    setSelectedStatusItems([]);
    handleClearFilters();
    setTimeout(() => setIsClearDate(false), 1000);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);
  const kitterDropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        kitterDropdownRef.current &&
        !kitterDropdownRef.current.contains(event.target as Node)
      ) {
        setKitterDropdownOpen(false);
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <tr style={{ zIndex: 10000 }}>
      {/* <th
        style={{
          height: '88px',
          backgroundColor: '#F1F5F9',
        }}
      /> */}
      <th
        style={{
          width: '168.6px',
          height: '88px',
          backgroundColor: '#F1F5F9',
        }}
      >
        <SearchField
          search={searchPid}
          setSearch={setSearchPid}
          clearSearch={handleClearSearch}
          slug="pid"
        />
      </th>
      <th
        style={{
          width: '165.6px',
          height: '88px',
          backgroundColor: '#F1F5F9',
        }}
      >
        <SearchField
          search={searchCustomerName}
          setSearch={setSearchCustomerName}
          clearSearch={handleClearSearch}
          slug="customerName"
        />
      </th>
      <th
        style={{
          width: '165.6px',
          height: '88px',
          backgroundColor: '#F1F5F9',
        }}
      >
        <SearchField
          search={email}
          setSearch={setEmail}
          clearSearch={handleClearSearch}
          slug="email"
        />
      </th>
      <th
        style={{
          width: '165.6px',
          height: '88px',
          backgroundColor: '#F1F5F9',
        }}
      >
        <SearchField
          search={phone}
          setSearch={setPhone}
          clearSearch={handleClearSearch}
          slug="phone"
        />
      </th>
      <th
        style={{
          width: '165.6px',
          height: '88px',
          backgroundColor: '#F1F5F9',
        }}
      >
        <CalendarFilter
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          isClearFilter={isClearDate}
        />
      </th>
      <th
        style={{
          width: '165.6px',
          height: '88px',
          backgroundColor: '#F1F5F9',
        }}
      >
        <DropDownFilter
          searchItems={statusItems}
          handleApply={handleStatusApply}
          search={searchStatus}
          setSearch={setSearchStatus}
          selectedItems={selectedStatusItems}
          setSelectedItems={setSelectedStatusItems}
          dropdownOpen={dropdownOpen}
          setDropdownOpen={setDropdownOpen}
          dropdownRef={dropdownRef}
        />
      </th>
      <th
        style={{
          width: '225px',
          height: '106.6px',
          backgroundColor: '#F1F5F9',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <DropDownFilter
          searchItems={kitterItems}
          handleApply={handleKitterApply}
          search={searchKitter}
          isLast
          setSearch={setSearchKitter}
          selectedItems={selectedKitterItems}
          setSelectedItems={setSelectedKitterItems}
          dropdownOpen={kitterDropdownOpen}
          setDropdownOpen={setKitterDropdownOpen}
          dropdownRef={kitterDropdownRef}
        />
        <span>
          <span
            style={{
              fontSize: '12px',
              marginTop: '8px',
              float: 'right',
              display: 'flex',
              alignItems: 'center',
            }}
            className="clear-all-filters"
            role="button"
            onClick={clearFilters}
          >
            Clear All Filters
            <XCircleIcon />
          </span>
        </span>
      </th>
    </tr>
  );
};
export default React.memo(OrderTableFilters);

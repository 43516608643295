import axios from 'axios';
import DEV_API_URL from '../constant/default-dev-apiurl';

const apiUrl = process.env.REACT_APP_API_URL || DEV_API_URL;

const API_URL = apiUrl;

const api = axios.create({
  baseURL: `${API_URL}${API_URL.endsWith('/') ? '' : '/'}`,
  headers: {
    'Content-Type': 'application/json',
  },
  validateStatus: () => true,
});

const setTokenInterceptor = async (accessToken: string) => {
  api.interceptors.request.use(async (config) => {
    const requestConfig = config;
    if (accessToken) {
      requestConfig.headers.Authorization = `Bearer ${accessToken}`;
    }
    return requestConfig;
  });
  return api;
};

export default setTokenInterceptor;

import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import UsersTable from '../components/UserTable';
import useUserList from '../hooks/reactQuery/useUserList';

interface IProps {
  search: string | undefined;
  // eslint-disable-next-line no-unused-vars
  setSearch: (s: string) => void;
  // eslint-disable-next-line no-unused-vars
  handleModalOpen: (data: any) => void;
}
const ITEMS_LIMIT_PER_PAGE = 10;

const Users = ({ search = '', setSearch, handleModalOpen }: IProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [roles, setRoles] = useState([]);
  const { data, isLoading, isFetching } = useUserList({
    limit: ITEMS_LIMIT_PER_PAGE,
    page: currentPage,
    filter: {
      firstName,
      lastName,
      email,
      role: roles[0],
    },
  });

  return (
    <div className="main-content">
      {isLoading ? (
        <div className="spinner-position">
          <Spinner />
        </div>
      ) : (
        <UsersTable
          data={data?.pages[0]}
          search={search}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setSearch={setSearch}
          handleModalOpen={handleModalOpen}
          itemsPerPage={ITEMS_LIMIT_PER_PAGE}
          isFetching={isFetching}
          firstName={firstName}
          lastName={lastName}
          email={email}
          roles={roles}
          setFirstName={setFirstName}
          setEmail={setEmail}
          setLastName={setLastName}
          setRoles={setRoles}
        />
      )}
    </div>
  );
};

export default Users;

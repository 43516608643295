import React from 'react';

const GearSixIcon = () => {
  return (
    <svg width="20" height="20" viewBox="2 0 23 22" fill="none">
      <path
        d="M4.08595 7.65616C4.22658 7.35928 4.39064 7.07803 4.57814 6.80459L4.54689 4.77334C4.54689 4.58584 4.62502 4.40616 4.77345 4.28116C5.50002 3.67178 6.32814 3.17959 7.2422 2.85147C7.42189 2.78897 7.6172 2.80459 7.77345 2.90616L9.51564 3.95303C9.84377 3.92959 10.1719 3.92959 10.5 3.95303L12.2422 2.90616C12.4063 2.8124 12.6016 2.78897 12.7813 2.85147C13.6719 3.17178 14.5078 3.64834 15.25 4.27334C15.3906 4.39053 15.4766 4.57803 15.4688 4.76553L15.4375 6.79678C15.625 7.07022 15.7891 7.35147 15.9297 7.64834L17.7031 8.63272C17.8672 8.72647 17.9844 8.88272 18.0156 9.07022C18.1797 9.99991 18.1875 10.9687 18.0156 11.914C17.9844 12.1015 17.8672 12.2577 17.7031 12.3515L15.9297 13.3358C15.7891 13.6327 15.625 13.914 15.4375 14.1874L15.4688 16.2187C15.4688 16.4062 15.3906 16.5858 15.2422 16.7108C14.5156 17.3202 13.6875 17.8124 12.7735 18.1405C12.5938 18.203 12.3985 18.1874 12.2422 18.0858L10.5 17.039C10.1719 17.0624 9.84377 17.0624 9.51564 17.039L7.77345 18.0858C7.60939 18.1796 7.41408 18.203 7.23439 18.1405C6.34377 17.8202 5.50783 17.3437 4.76564 16.7187C4.62502 16.6015 4.53908 16.414 4.54689 16.2265L4.57814 14.1952C4.39064 13.9218 4.22658 13.6405 4.08595 13.3437L2.31252 12.3593C2.14845 12.2655 2.03127 12.1093 2.00002 11.9218C1.83595 10.9921 1.82814 10.0233 2.00002 9.07803C2.03127 8.89053 2.14845 8.73428 2.31252 8.64053L4.08595 7.65616Z"
        stroke="#475569"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99994 14.2499C12.071 14.2499 13.7499 12.571 13.7499 10.4999C13.7499 8.42886 12.071 6.74992 9.99994 6.74992C7.92887 6.74992 6.24994 8.42886 6.24994 10.4999C6.24994 12.571 7.92887 14.2499 9.99994 14.2499Z"
        stroke="#475569"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GearSixIcon;

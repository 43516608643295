import React from 'react';

const ChangePasswordIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="2 0 26 26"
      fill="none"
      stroke="currentColor"
      opacity={0.7}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
      <path d="M7 11V7a5 5 0 0 1 10 0v4" />
    </svg>
  );
};

export default ChangePasswordIcon;

import React from 'react';

const LeftArrowIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 8 40 40" fill="none">
      <g clipPath="url(#clip0_331_4367)">
        <path
          d="M23.705 15.41L22.295 14L16.295 20L22.295 26L23.705 24.59L19.125 20L23.705 15.41Z"
          fill="#475569"
        />
      </g>
      <defs>
        <clipPath id="clip0_331_4367">
          <rect width="40" height="40" rx="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LeftArrowIcon;

import React, { useCallback, useEffect, useState } from 'react';
import { Table, Pagination, Dropdown, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import PencilIcon from './icons/PencilIcon';
import TrashIcon from './icons/TrashIcon';
import BackArrow from './icons/BackArrowIcon';
import PlusIcon from './icons/PlusIcon';
import UserData from '../types/IUserResponse';
import useQueryParams from '../hooks/useQueryParams';
import useDeleteUser from '../hooks/reactQuery/useDeleteUser';
import DeleteConfirmationModal from './modals/DeleteConfirmationModal';
import UserTableFilters from './UserTableFilters';
import ChangePasswordIcon from './icons/ChangePasswordIcon';

interface UserTableProps {
  data: UserData | undefined;
  search: string | undefined;
  itemsPerPage: number;
  setSearch: (s: string) => void;
  handleModalOpen: (data: any) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  isFetching: boolean;
  firstName: string;
  lastName: string;
  setFirstName: (e: any) => void;
  setLastName: (e: any) => void;
  setEmail: (e: any) => void;
  email: string;
  setRoles: (e: any) => void;
  roles: string[];
}

const UserTable: React.FC<UserTableProps> = ({
  data,
  search,
  setSearch,
  handleModalOpen,
  itemsPerPage,
  currentPage,
  setCurrentPage,
  isFetching,
  roles,
  setRoles,
  email,
  setLastName,
  setEmail,
  setFirstName,
  firstName,
  lastName,
}) => {
  const { params, searchParams, query } = useQueryParams();
  const history = useHistory();
  const [initialMount, setInitialMount] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemId, setItemId] = useState('');
  const { mutate: deleteOrder } = useDeleteUser(itemId);
  const [roleItems, setRolesItems] = useState<string[]>([]);

  const totalPages = data?.maxPage;

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleApplyRole = (itemsRoles: any) => {
    setRoles(itemsRoles);
  };

  const handleDelete = (userId: string) => {
    deleteOrder(
      { userId },
      {
        onSuccess: () => {
          toast.success(`User Deleted`);
          setIsModalOpen(false);
        },
        onError: () => {
          toast.error(`Failed to delete`);
        },
      },
    );
  };

  const [pageSize, setPageSize] = useState<number>(itemsPerPage);

  useEffect(() => {
    if (currentPage) {
      searchParams.set('page', currentPage?.toString());
    }
    if (pageSize) {
      searchParams.set('pageSize', pageSize?.toString());
    }
    if (firstName) {
      searchParams.set('firstName', encodeURIComponent(firstName));
    }
    if (lastName) {
      searchParams.set('lastName', encodeURIComponent(lastName));
    }
    if (email) {
      searchParams.set('email', encodeURIComponent(email));
    }
    if (roles?.length) {
      searchParams.set('role', encodeURIComponent(roles[0]));
    }
    history.push({
      search: searchParams?.toString(),
    });
  }, [currentPage, firstName, lastName, email, roles]);

  const updateStateFromURL = useCallback(() => {
    const storedCurrentPage = parseInt(params.page?.toString(), 10);
    const storedPageSize = parseInt(params.pageSize?.toString(), 10);
    const storedFirstName = params.firstName?.toString();
    const storedLastName = params.lastName?.toString();
    const storedRole = params.role?.toString();
    const storedEmail = params.email?.toString();
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(storedCurrentPage) && storedCurrentPage !== currentPage) {
      setCurrentPage(storedCurrentPage);
    }
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(storedPageSize) && storedPageSize !== pageSize) {
      setPageSize(storedPageSize);
    }
    if (storedFirstName && storedFirstName !== firstName) {
      setFirstName(storedFirstName);
    }
    if (storedLastName && storedLastName !== lastName) {
      setLastName(storedLastName);
    }
    if (storedEmail && storedEmail !== email) {
      setEmail(storedEmail);
    }
    if (storedRole && storedRole !== roles?.[0]) {
      setRoles([storedRole]);
      setRolesItems([storedRole]);
    }
  }, [query]);

  useEffect(() => {
    updateStateFromURL();
    setTimeout(() => setInitialMount(false), 10);
  }, []);

  useEffect(() => {
    if (!initialMount) {
      updateStateFromURL();
    }
  }, [query]);

  return (
    <>
      <div
        style={{
          backgroundColor: '#ffffff',
          borderRadius: '24px',
        }}
        className="user-table-custom-boundries"
      >
        {search ? (
          <h4
            style={{ borderBottom: 2, marginBottom: '20px', display: 'flex' }}
          >
            <span style={{ display: 'flex', flexDirection: 'column' }}>
              <span
                style={{ fontSize: 14, fontWeight: 500 }}
                role="button"
                onClick={() => {
                  setSearch('');
                  history.push('/');
                }}
              >
                <BackArrow /> Back to User List
              </span>
              Search Results
            </span>
            <span
              style={{
                float: 'right',
                fontSize: 14,
                fontWeight: 500,
                marginLeft: 'auto',
              }}
            >
              Results Found:
              <span style={{ fontWeight: 550, fontSize: 14 }}> 02</span>
            </span>
          </h4>
        ) : (
          <h4 style={{ borderBottom: 2, marginBottom: '20px' }}>
            User List
            <span
              style={{
                float: 'right',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <button
                className="create-button"
                onClick={() =>
                  handleModalOpen({
                    modal: 'create-user',
                  })
                }
              >
                Create User
                <PlusIcon />
              </button>
            </span>
          </h4>
        )}
        <Table
          bordered={false}
          className="user-custom-table"
          responsive
          style={{ marginBottom: '20px' }}
        >
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Actions</th>
            </tr>
            <UserTableFilters
              searchFirstName={firstName}
              setSearchEmail={setEmail}
              searchEmail={email}
              searchLastName={lastName}
              setSearchFirstName={setFirstName}
              setSearchLastName={setLastName}
              setSelectedRoles={setRolesItems}
              userRoles={roleItems}
              searchParams={searchParams}
              roleItems={data?.selections?.Role || ['Member', 'Admin']}
              handleApplyRole={handleApplyRole}
            />
          </thead>
          <tbody
            style={{
              borderColor: 'transparent',
              display: !data?.items.length || isFetching ? 'table-caption' : '',
            }}
          >
            {!data?.items.length || isFetching ? (
              <span className="spinner-position-between-table">
                {isFetching ? <Spinner /> : 'There is no user'}
              </span>
            ) : (
              data?.items?.map((user) => (
                <tr key={user.id}>
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td style={{ minWidth: '120px' }}>
                    <span
                      role="button"
                      onClick={() =>
                        handleModalOpen({
                          modal: 'update-user-password',
                          data: {
                            ...user,
                          },
                        })
                      }
                    >
                      <ChangePasswordIcon />
                    </span>
                    <span
                      role="button"
                      className="ml-2"
                      onClick={() =>
                        handleModalOpen({
                          modal: 'user',
                          data: {
                            ...user,
                          },
                        })
                      }
                    >
                      <PencilIcon />
                    </span>
                    <span
                      role="button"
                      className="ml-2"
                      onClick={() => {
                        setItemId(user.id);
                        setIsModalOpen(true);
                      }}
                    >
                      <TrashIcon />
                    </span>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Pagination>
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              style={{ fontWeight: 'bolder' }}
            >
              {'<'}
            </Pagination.Prev>
            {currentPage >= 3 ? <Pagination.Ellipsis /> : null}
            {[...Array(totalPages)].map((_, index: number) => {
              if (
                index + 1 === currentPage ||
                (index >= currentPage - 3 && index <= currentPage + 1)
              ) {
                return (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                );
              }
              return null;
            })}
            {currentPage + 3 <= (totalPages || 0) ? (
              <Pagination.Ellipsis />
            ) : null}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              style={{ fontWeight: 'bolder' }}
            >
              {'>'}
            </Pagination.Next>
          </Pagination>
        </div>
      </div>
      <DeleteConfirmationModal
        onDelete={handleDelete}
        onHide={() => setIsModalOpen(false)}
        show={isModalOpen}
        id={itemId}
      />
    </>
  );
};

export default UserTable;

import React from 'react';

const XCircleIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M10 6L6 10M10 10L6 6M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
        stroke="#DC2626"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default XCircleIcon;

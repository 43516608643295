const getRandomClass = (status: string): string => {
  const colorMap: { [key: string]: string } = {
    'Ready for Kitting': 'ready-for-kitting-status',
    'New Order': 'new-order-status',
    'Kitting in Progress': 'kitting-in-progress-status',
    'Ready for Shipping': 'ready-for-shipping-status',
    'Shipping in Progress': 'shipping-in-progress-status',
    'Order in Transit': 'order-in-transit-status',
    'Order Delivered': 'order-delivered-status',
    Cancelled: 'cancelled-status',
  };

  return colorMap[status] || colorMap.default;
};

export default getRandomClass;

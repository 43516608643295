import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from 'react-query';
import setToken from '../../utils/api';
import { IApiResponse } from '../../types/IApiResponse';
import { UserItem } from '../../types/IUserResponse';

export default function useUserUpdate(id: number | undefined) {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  return useMutation<any, any, Partial<UserItem>>(
    ['user-update'],
    async (data: any) => {
      if (!id && !data.id) {
        return null;
      }
      const token = await getAccessTokenSilently();
      const api = await setToken(token);
      const response = await api.put<IApiResponse<any>>(
        `api/users/${id || data.id}`,
        {
          firstName: data.firstName,
          lastName: data.lastName,
          role: data.role,
        },
      );

      if (response.status !== 200) {
        throw response.data;
      }
      return 'ok';
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['user-details', id]);
        queryClient.invalidateQueries('user-list');
      },
    },
  );
}

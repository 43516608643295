import React from 'react';

const Frame1Icon = () => {
  return (
    <svg width="20" height="20" viewBox="2 0 23 22" fill="none">
      <g clipPath="url(#clip0_187_431)">
        <path
          d="M10.0642 17.1668H5.83337V3.8335H15V15.5002H10.7692"
          stroke="#475569"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_187_431">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Frame1Icon;

import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from 'react-query';
import setToken from '../../utils/api';
import { IApiResponse } from '../../types/IApiResponse';
import { IOrderItem } from '../../types/IOrderResponse';

export default function useChangePassword() {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation<any, any, Partial<IOrderItem>>(
    ['change-password'],
    async (data: any) => {
      const token = await getAccessTokenSilently();
      const api = await setToken(token);
      const response = await api.put<IApiResponse<any>>(
        `api/users-profile/change-password`,
        {
          password: data?.password,
          confirmPassword: data?.confirmPassword,
          oldPassword: data?.oldPassword,
        },
      );
      if (response.status !== 200) {
        throw response.data;
      }
      return 'ok';
    },
  );
}

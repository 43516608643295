import { useQuery } from 'react-query';

import { useAuth0 } from '@auth0/auth0-react';
import setToken from '../../utils/api';
import { IApiResponse } from '../../types/IApiResponse';

export default function useMenuItems() {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery<IApiResponse<any>, IApiResponse<any>, any>(
    ['menu-items'],
    async () => {
      const token = await getAccessTokenSilently();
      const api = await setToken(token);
      const response = await api.get<IApiResponse<any>>(`api/menu`, {});

      if (response?.status !== 200) {
        throw new Error(`Couldn't find menu`);
      }

      return response.data;
    },
    {
      keepPreviousData: true,
    },
  );
}
